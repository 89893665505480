import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    AppBar,
    IconButton,
    Typography,
    Grid,
    Menu,
    MenuItem,
    Toolbar,
    ListItemIcon,
    ListItemText,
    MenuList,
    Slide,
} from '@mui/material';

import navLogo from '../../../../assets/logos/hertex-logo-long.png';
import MenuIcon from '@mui/icons-material/Menu';
import {
    Clear,
    KeyboardArrowDown,
    KeyboardArrowUp,
    SearchOutlined,
    PersonOutlined,
    SettingsOutlined,
} from '@mui/icons-material';
import { useQuery, useQueryClient } from 'react-query';
import SearchModal from '../../modal/SearchModal';
import { apiGetApplicationNamesByItemGroup } from '../../../../api/apiCategories';
import {
    ProductStatResponseModel,
    ProductStatsModel,
    apiPostProductStats,
} from '../../../../api/apiProduct';
import { apiProductProperties } from '../../../../api/apiMultiFilter';

function Navbar() {
    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [displayMegaMenu, setDisplayMegaMenu] = useState<boolean>();
    const [displayMobileItemMenu, setDisplayMobileItemMenu] =
        useState<boolean>();
    const [productStats, setProductStats] =
        useState<ProductStatResponseModel | null>(null);
    // const [isLoading, setIsLoading] = useState<boolean>(false);
    const [menuContent, setMenuContent] = useState<any[]>([]);
    const [selectedPage, setSelectedPage] = useState<string | null>(null);
    // const [rugsContent, setRugsContent] = useState<string[]>([]);
    const [fabricsContent, setFabricsContent] = useState<string[]>([]);
    const [wallpaperContent, setWallpaperContent] = useState<string[]>([]);
    const [brandsContent, setBrandsContent] = useState<string[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    enum ItemGroupCode {
        FABRIC = '005',
        WALLPAPER = '001',
        RUGS = '004',
        HOMEWARE = '000',
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleOpenModal = () => {
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
        queryClient.invalidateQueries('search-term');
    };

    const productStatsData = {
        data: {
            items_groups_stats: [],
        },
    };

    useQuery<ProductStatResponseModel, Error>(
        'productStats',
        () => apiPostProductStats(productStatsData),
        {
            onSuccess: (data) => {
                setProductStats(data);
            },
            onError: (error) => {
                console.error(error);
            },
            cacheTime: 15 * 60 * 1000,
        }
    );

    useQuery<ProductStatResponseModel, Error>(
        'brands',
        () =>
            apiProductProperties({
                data: {
                    items: {
                        get_count: false,
                        item_group: '',
                        required_item_info: ['brand'],
                    },
                },
            } as ProductStatsModel),
        {
            onSuccess: (data) => {
                const apiArray: string[] | undefined = data.brand;
                const internationals = 'Internationals';
                setBrandsContent([...(apiArray ?? []), internationals].sort());
            },
            onError: (error) => {
                console.error(error);
            },
            cacheTime: 15 * 60 * 1000,
        }
    );

    // useQuery(
    //   "rugsContent",
    //   () => apiGetApplicationNamesByItemGroup(ItemGroupCode.RUGS),
    //   {
    //     onSuccess: (response) => {
    //       setRugsContent(response.appliactions || []);
    //     },
    //     onError: (err) => {
    //       console.error(err);
    //     },
    //     cacheTime: 15 * 60 * 1000,
    //   }
    // );

    useQuery(
        'fabricsContent',
        () => apiGetApplicationNamesByItemGroup(ItemGroupCode.FABRIC),
        {
            onSuccess: (response) => {
                setFabricsContent(['Fabric', ...response.appliactions]);
            },
            onError: (err) => {
                console.error(err);
            },
            cacheTime: 15 * 60 * 1000,
        }
    );

    useQuery(
        'wallpaperContent',
        () => apiGetApplicationNamesByItemGroup(ItemGroupCode.WALLPAPER),
        {
            onSuccess: (response) => {
                setWallpaperContent([
                    'Wallpaper Calculator',
                    ...response.appliactions,
                ]);
            },
            onError: (err) => {
                console.error(err);
            },
            cacheTime: 15 * 60 * 1000,
        }
    );

    // const homewareContent = productStats?.homeware
    //   ? [...Object.keys(productStats.homeware), "Outdoor"]
    //   : [];

    // !Outdoor removed - client may revert
    const pages = [
        'Fabric',
        'Wallpaper',
        'Rugs',
        'Homeware',
        // 'Outdoor',
        'Brands',
        'Company',
    ];

    const pageRoutes = {
        Wallpaper: '/item_group/001',
        Fabric: '/item_group/005',
        // Rugs: "/item_group/004",
        // Homeware: "/item_group/000",
    };

    const homewareContent = ['shop homeware online @ hertexhaus.co.za'];

    const rugsContent = ['shop rugs online @ hertexhaus.co.za'];

    // const companyContent = ["About Us", "Explore Our Spaces", "Careers @ Hertex"];
    const companyContent = ['About Us', 'Explore Our Spaces', 'Careers'];

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const getMenuItemContent = (page: string) => {
        switch (page) {
            case 'Fabric':
                setMenuContent(fabricsContent);
                break;
            case 'Company':
                setMenuContent(companyContent);
                break;
            case 'Brands':
                setMenuContent(brandsContent);
                break;
            case 'Homeware':
                setMenuContent(homewareContent);
                break;
            case 'Wallpaper':
                setMenuContent(wallpaperContent.sort());
                break;
            case 'Rugs':
                setMenuContent(rugsContent);
                break;
        }
    };

    const handlePageClick = (page: string) => {
        if (selectedPage === page) {
            setSelectedPage(null);
        } else {
            setSelectedPage(page);
            setDisplayMobileItemMenu(true);
            getMenuItemContent(page);
        }
    };

    const handleNavigate = (_: string, category: string) => {
        switch (category) {
            case 'Fabric':
                navigate(`../item_group/${ItemGroupCode.FABRIC}`);
                break;
            case 'Curtaining':
            case 'Upholstery':
            case 'Dual Purpose':
                const formattedCategory = category
                    .toLowerCase()
                    .replace('-', ' ');
                navigate(`../item_group/005/application/${formattedCategory}`);
                break;

            // !Company

            case 'About Us':
                navigate('../about-us');
                break;
            case 'Explore Our Spaces':
                navigate('../store-locator');
                break;
            case 'Careers':
                navigate('../careers');
                break;

            case 'shop rugs online @ hertexhaus.co.za':
                window.open(
                    'https://hertexhaus.co.za/pages/rugs',
                    '_blank'
                );
                break;

            case 'shop homeware online @ hertexhaus.co.za':
                window.open('https://hertexhaus.co.za/', '_blank');
                break;

            // case "Bedroom":
            //   navigate("../item_group/006");
            //   break;
            // case "Illumination":
            //   navigate("../item_group/007");
            //   break;
            // case "Tableware":
            //   navigate("../item_group/008");
            //   break;
            // case "Furniture":
            //   navigate("../item_group/009");
            //   break;
            // case "Decorative":
            //   navigate("../item_group/010");
            //   break;
            // case "Tradehaus Rugs":
            //   navigate("../item_group/011");
            //   break;
            // case "Throws, Quilts and Furs":
            //   navigate("../item_group/013");
            //   break;
            // case "Wall Decor":
            //   navigate("../item_group/014");
            //   break;
            // case "Curtains":
            //   navigate("../item_group/015");
            //   break;
            // case "Bathroom":
            //   navigate("../item_group/016");
            //   break;
            // case "Scatters":
            //   navigate("../item_group/018");
            //   break;

            // // !Wallpaper
            case 'Hertex Wallpaper':
                navigate('../item_group/001/application/hertex wallpaper');
                break;
            case 'Wallpaper Calculator':
                navigate('../wallpaper-calculator');
                break;
            // // !Rugs
            // case "Compact Runners":
            //   navigate("../item_group/004/application/compact runners");
            //   break;

            // case "Hallway Runners":
            //   navigate("../item_group/004/application/hallway runners");
            //   break;

            // case "Kitchen Runners":
            //   navigate("../item_group/004/application/kitchen runners");
            //   break;

            // case "Rugs":
            //   navigate("../item_group/004/application/rugs");
            //   break;

            case 'HAUS':
                window.open('https://hertexhaus.co.za/', '_blank');
                break;

            case 'Internationals':
                navigate('/internationals');
                break;

            default:
                if (category) {
                    const encodedBrand = encodeURIComponent(category);
                    navigate(`../item_group/9000/brand/${encodedBrand}`);
                }
                break;
        }
    };

    return (
        <>
            <AppBar position='sticky' color='secondary' sx={{ boxShadow: '0' }}>
                {/* Relative positioning for mega menu*/}
                <Box sx={{ position: 'relative' }}>
                    <Toolbar disableGutters>
                        <Box
                            sx={{
                                display: {
                                    xs: 'none',
                                    lg: 'flex',
                                    alignItems: 'left',
                                },
                                ml: '2%',
                                width: { md: '15%', lg: '10%' },
                            }}
                        >
                            <img
                                style={{
                                    width: '100%',
                                    cursor: 'pointer',
                                }}
                                src={navLogo}
                                alt='Nav logo'
                                onClick={() => {
                                    navigate('/');
                                }}
                            />
                        </Box>

                        {/* Mobile View */}
                        <Box
                            sx={{
                                width: '100%',
                                display: { xs: 'flex', lg: 'none' },
                                justifyContent: 'space-between',
                                px: '10px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: {
                                        xs: 'flex',
                                        lg: 'none',
                                    },
                                    width: '150px',
                                    justifySelf: 'center',
                                    alignSelf: 'center',
                                    mr: 2,
                                }}
                            >
                                <img
                                    style={{
                                        width: '100%',
                                        cursor: 'pointer',
                                    }}
                                    src={navLogo}
                                    alt='Nav logo'
                                    onClick={() => {
                                        navigate('/');
                                    }}
                                />
                            </Box>
                            <Box>
                                <IconButton
                                    sx={{ padding: '1rem' }}
                                    size='large'
                                    aria-label='show cart quantity'
                                    color='inherit'
                                    onClick={() => handleOpenModal()}
                                >
                                    <SearchOutlined />
                                </IconButton>
                                <IconButton
                                    size='large'
                                    aria-label='account of current user'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    onClick={handleOpenNavMenu}
                                    color='inherit'
                                >
                                    {anchorElNav ? <Clear /> : <MenuIcon />}
                                </IconButton>
                            </Box>
                            {/* Mobile Menu */}
                            <Menu
                                id='menu-appbar'
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                marginThreshold={0}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {
                                        xs: 'block',
                                        lg: 'none',
                                    },
                                }}
                                elevation={0}
                                PaperProps={{
                                    style: {
                                        width: '100%',
                                        maxWidth: '100%',
                                        height: '100%',
                                        maxHeight: 'calc(100vh - 50px)',
                                        marginTop: '50px',
                                        left: 0,
                                        right: 0,
                                        borderRadius: '0',
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                    },
                                }}
                                TransitionComponent={Slide}
                                transitionDuration={300}
                            >
                                <Box>
                                    <></>
                                    <MenuList>
                                        {pages.map((page) => (
                                            <MenuItem
                                                key={page}
                                                onClick={() => {
                                                    handlePageClick(page);
                                                    if (page === 'Outdoor') {
                                                        navigate(
                                                            `/item_group/9001/application_type/outdoor`
                                                        );
                                                        return;
                                                    }
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            justifyContent:
                                                                'space-between',
                                                        }}
                                                    >
                                                        <Box>
                                                            <ListItemText>
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily:
                                                                            'sans-serif',
                                                                        fontWeight:
                                                                            'semibold',
                                                                        fontSize:
                                                                            '14px',
                                                                        textTransform:
                                                                            'uppercase',
                                                                        letterSpacing:
                                                                            '1.68px',
                                                                    }}
                                                                >
                                                                    {page}
                                                                </Typography>
                                                            </ListItemText>
                                                        </Box>
                                                        <Box>
                                                            <ListItemIcon>
                                                                <KeyboardArrowDown fontSize='small' />
                                                            </ListItemIcon>
                                                        </Box>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            background:
                                                                '#EEEEEE',
                                                            width: '1000px',
                                                            pl: '50px',
                                                            ml: '-50px',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        {selectedPage ===
                                                            page && (
                                                            <MenuList>
                                                                {menuContent.map(
                                                                    (
                                                                        elem: string
                                                                    ) => (
                                                                        <MenuItem
                                                                            key={
                                                                                elem
                                                                            }
                                                                            // onClick={handleCloseNavMenu}
                                                                            onClick={() => {
                                                                                handleNavigate(
                                                                                    selectedPage!,
                                                                                    elem
                                                                                );
                                                                                handleCloseNavMenu();
                                                                            }}
                                                                            sx={{
                                                                                p: '0',
                                                                            }}
                                                                        >
                                                                            <ListItemText>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'sans-serif',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        textTransform:
                                                                                            'uppercase',
                                                                                        fontSize:
                                                                                            '10px',
                                                                                        textAlign:
                                                                                            'left',
                                                                                        width: '100%',
                                                                                        whiteSpace:
                                                                                            'nowrap',
                                                                                        letterSpacing:
                                                                                            '1.68px',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        elem
                                                                                    }
                                                                                </Typography>
                                                                            </ListItemText>
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </MenuList>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </MenuList>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '10px',
                                            px: '10px',
                                        }}
                                    >
                                        <a
                                            href='https://www.hertextradehaus.co.za/login'
                                            rel='noreferrer'
                                            target='_blank'
                                            style={{
                                                textDecoration: 'none',
                                            }}
                                        >
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                disableElevation
                                                sx={{
                                                    mb: '10px',
                                                }}
                                            >
                                                Login to hertex trade
                                            </Button>
                                        </a>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            disableElevation
                                            onClick={() =>
                                                // navigate('/register')
                                                window.open(
                                                    'https://www.hertextradehaus.co.za/login'
                                                )
                                            }
                                        >
                                            Register
                                        </Button>
                                    </Box>
                                </Box>
                            </Menu>
                        </Box>

                        {/* Desktop Menu */}
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'none', lg: 'flex' },
                                justifyContent: 'center',
                                gap: '2%',
                            }}
                        >
                            {pages.map((page, index: number) => (
                                <Box key={index}>
                                    <Button
                                        key={page}
                                        onClick={(e) => {
                                            handleCloseNavMenu();
                                            if (page === 'Outdoor') {
                                                navigate(
                                                    `/item_group/9001/application_type/outdoor`
                                                );
                                                return;
                                            }
                                            if (page === 'Wallpaper') {
                                                navigate(
                                                    '../item_group/001/application/hertex wallpaper'
                                                );
                                                return;
                                            }
                                            navigate(
                                                pageRoutes[
                                                    page as keyof typeof pageRoutes
                                                ]
                                            );
                                        }}
                                        onMouseEnter={() => {
                                            if (page === 'Outdoor') {
                                                setDisplayMegaMenu(false);
                                            } else {
                                                setDisplayMegaMenu(true);
                                                getMenuItemContent(page);
                                            }
                                        }}
                                        sx={{
                                            fontFamily: 'Sans-serif',
                                            fontSize: '14px',
                                            fontWeight: '300',
                                            px: '5px',
                                            letterSpacing: '3px',
                                        }}
                                    >
                                        {page}
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                        <Box
                            sx={{
                                display: { xs: 'none', lg: 'flex' },
                                mr: '2%',
                            }}
                        >
                            <IconButton
                                sx={{ padding: '1rem' }}
                                size='large'
                                aria-label='show cart quantity'
                                color='inherit'
                                onClick={() => handleOpenModal()}
                            >
                                <SearchOutlined />
                            </IconButton>

                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        height: '100%',
                                    }}
                                >
                                    <Button
                                        disableElevation
                                        variant='outlined'
                                        aria-label='login'
                                        onClick={handleClick}
                                        endIcon={
                                            anchorEl ? (
                                                <KeyboardArrowUp />
                                            ) : (
                                                <KeyboardArrowDown />
                                            )
                                        }
                                    >
                                        <Box>Trade Login</Box>
                                    </Button>
                                    <Menu
                                        elevation={1}
                                        PaperProps={{
                                            sx: {
                                                width: anchorEl
                                                    ? anchorEl.clientWidth
                                                    : undefined,
                                                borderRadius: '0',
                                            },
                                        }}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        id='trade-portal-menu'
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby':
                                                'trade-portal-button',
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() =>
                                                window.open(
                                                    'https://www.hertextradehaus.co.za/login'
                                                )
                                            }
                                        >
                                            {' '}
                                            <ListItemIcon>
                                                <PersonOutlined
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        color: '#000',
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography
                                                    sx={{
                                                        color: '#000',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        textTransform:
                                                            'uppercase',
                                                        letterSpacing: '1.68px',
                                                    }}
                                                >
                                                    Login
                                                </Typography>
                                            </ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                // navigate('/register')
                                                window.open(
                                                    'https://www.hertextradehaus.co.za/login'
                                                )
                                            }
                                        >
                                            {' '}
                                            <ListItemIcon>
                                                <SettingsOutlined
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        color: '#000',
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography
                                                    sx={{
                                                        color: '#000',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        textTransform:
                                                            'uppercase',
                                                        letterSpacing: '1.68px',
                                                    }}
                                                >
                                                    Register
                                                </Typography>
                                            </ListItemText>
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            </Box>
                        </Box>
                    </Toolbar>

                    {/* Mega Menu Content */}
                    {displayMegaMenu && (
                        <Box
                            sx={{
                                width: '100%',
                                position: 'absolute',
                                bottom: '-30',
                                zIndex: '999',
                                backgroundColor: '#F9FAFB',
                                display: 'flex',
                                padding: '2rem',
                            }}
                            onMouseLeave={() => {
                                setDisplayMegaMenu(false);
                            }}
                        >
                            <Grid container justifyContent='center'>
                                {menuContent?.map(
                                    (elem: any, index: number) =>
                                        elem !== 'Fabric' && (
                                            <Grid
                                                key={index}
                                                item
                                                xs={3}
                                                spacing={2}
                                                sx={{
                                                    backgroundColor: '#fff',
                                                    height: '2rem',
                                                    margin: '0.5rem 0.5rem',
                                                    padding: '2rem',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'pointer',
                                                    textTransform: 'uppercase',
                                                    fontSize: '0.8rem',
                                                    lineHeight: '1.75rem',
                                                    letterSpacing: '0.12rem',
                                                }}
                                                onClick={() => {
                                                    handleNavigate(
                                                        selectedPage!,
                                                        elem
                                                    );
                                                }}
                                            >
                                                {elem}
                                            </Grid>
                                        )
                                )}
                            </Grid>
                        </Box>
                    )}
                </Box>
            </AppBar>
            <SearchModal
                modalIsOpen={modalIsOpen}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
            />
        </>
    );
}

export default Navbar;
