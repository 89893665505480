import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Button,
  MenuItem,
  Grid,
} from "@mui/material";
import navLogo from "../../../assets/logos/hertex-logo-long.png";
import MenuIcon from "@mui/icons-material/Menu";

const pages = ["Home"];
function ResponsiveAppBar() {
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    navigate("/");
  };

  return (
    <AppBar
      position="static"
      color="secondary"
      elevation={0}
      sx={{
        display: { xs: "none", lg: "block" },
      }}
    >
      <Toolbar disableGutters>
        <Grid
          sx={{
            width: "100%",
            display: { xs: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            m: "0 2rem",
          }}
        >
          <Box
            sx={{
              width: "150px",
            }}
          >
            <img
                                style={{
                                    width: '100%',
                                    cursor: 'pointer',
                                }}
                                src={navLogo}
                                alt='Nav logo'
                                onClick={() => {
                                    navigate('/');
                                }}
                            />
          </Box>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
export default ResponsiveAppBar;
