import { useNavigate } from 'react-router-dom';
import { Grid, Container, Box, Typography, Button } from '@mui/material';
import '../../../styles/featuredGrid.css';
import MissingContent from '../errorMessages/MissingContent';

function FeaturedGallery({
    images,
    hasVideo,
    invert,
    marginTop,
    marginBottom,
    title,
    description,
    itemGroup,
    application,
    collection,
}: any) {
    const navigate = useNavigate();

    const findImageByPosition = (position: string) => {
        return images.find((img: any) => img.position === position)?.url;
    };

    return (
        <>
            {/* Desktop*/}
            {images === null || images.length === 0 ? (
                <MissingContent sectionName='Featured Collection' />
            ) : (
                <>
                    <Container
                        maxWidth='lg'
                        className='grid-lg'
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate(
                                `../item_group/${itemGroup}/application/${application}/collection/${collection}`
                            );
                        }}
                    >
                        <Grid mt={5} mb={5} container spacing={2}>
                            <Grid id='inner-grid' item xs={6}>
                                <Box overflow='hidden' sx={{}}>
                                    <img
                                        style={{
                                            width: '100%',
                                            height: '80vh',
                                            objectFit: 'cover',

                                            padding: '1rem',
                                        }}
                                        src={findImageByPosition('top left')}
                                        alt='featured-0'
                                    />
                                </Box>
                                {invert === true ? (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '40vh',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {/* ! Add option to display image instead based on file type in the parent perhaps*/}
                                        {hasVideo ? (
                                            <video
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                                autoPlay
                                                muted
                                                loop
                                            >
                                                <source
                                                    src={''}
                                                    type='video/mp4'
                                                ></source>
                                            </video>
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: {
                                                        xs: '90%',
                                                        lg: '100%',
                                                    },
                                                    height: '100%',
                                                }}
                                            >
                                                <Typography variant='h4'>
                                                    {title}
                                                </Typography>
                                                <Typography textAlign='center'>
                                                    {description}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                ) : (
                                    <Box
                                        overflow='hidden'
                                        sx={{
                                            marginTop: '15px',
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: '100%',
                                                height: '40vh',
                                                // marginTop: '5px',
                                                objectFit: 'cover',
                                                paddingRight: '1rem',
                                                paddingLeft: '1rem',
                                            }}
                                            src={findImageByPosition(
                                                'bottom left'
                                            )}
                                            alt='featured-1'
                                        />
                                    </Box>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    padding: '5px',
                                }}
                            >
                                {invert === true ? (
                                    <Box overflow='hidden' height='600px'>
                                        <img
                                            style={{
                                                width: '100%',
                                                height: '40vh',
                                                // marginTop: '5px',
                                                objectFit: 'cover',
                                                // padding: '1rem',
                                            }}
                                            src={findImageByPosition(
                                                'bottom right'
                                            )}
                                            alt='featured-2'
                                        />
                                    </Box>
                                ) : (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {/* ! Add option to display image instead based on file type in the parent perhaps*/}
                                        {hasVideo ? (
                                            <video
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                                autoPlay
                                                muted
                                                loop
                                            >
                                                <source
                                                    src={''}
                                                    type='video/mp4'
                                                ></source>
                                            </video>
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: {
                                                        xs: '90%',
                                                        lg: '100%',
                                                    },
                                                    height: '40vh',
                                                    gap: '19px',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        p: '10px 12px 10px 12px',
                                                        background: '#EDEDED',
                                                    }}
                                                >
                                                    <Typography
                                                        textTransform={
                                                            'uppercase'
                                                        }
                                                        fontSize={'12px'}
                                                    >
                                                        Featured Collection
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    variant='h1'
                                                    fontSize={'46px'}
                                                    fontWeight={'500'}
                                                    lineHeight={'32px'}
                                                >
                                                    {title}
                                                </Typography>
                                                <Typography
                                                    textAlign='center'
                                                    fontSize={'16px'}
                                                    fontWeight={'400'}
                                                    lineHeight={'24px'}
                                                >
                                                    {description}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                )}
                                <Box overflow='hidden' marginTop='15px'>
                                    <img
                                        style={{
                                            width: '100%',
                                            height: '80vh',
                                            marginTop: '3px',
                                            objectFit: 'cover',
                                            // padding: '1rem',
                                        }}
                                        src={findImageByPosition(
                                            'bottom right'
                                        )}
                                        alt={`featured-2`}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>

                    {/* Mobile View */}

                    <Grid
                        className='grid-sm'
                        container
                        flexDirection='column'
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems='center'
                        mt={5}
                        mb={5}
                        paddingX='16px'
                    >
                        <Box
                            sx={{
                                maxWidth: '100%',
                            }}
                        >
                            <Box>
                                <img
                                    style={{
                                        width: '100%',
                                        height: '400px',
                                        objectFit: 'cover',
                                    }}
                                    src={findImageByPosition('bottom right')}
                                    alt='featured-2'
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    width: '100%',
                                    height: 'auto',
                                    pt: '1rem',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        p: '10px 12px 10px 12px',
                                        background: '#EDEDED',
                                    }}
                                >
                                    <Typography
                                        textTransform={'uppercase'}
                                        fontSize={'12px'}
                                    >
                                        Featured Collection
                                    </Typography>
                                </Box>
                                <Typography
                                    mt={3}
                                    variant='h5'
                                    fontSize={'40px'}
                                    fontWeight={'400'}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    fontSize={'16px'}
                                    mt={2}
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    {description}
                                </Typography>
                                <Button
                                    sx={{
                                        mt: 3,
                                        mb: 5,
                                        borderRadius: '0',
                                        padding: '1rem 3rem',
                                        backgroundColor: '#fff',
                                    }}
                                    variant='outlined'
                                    color='primary'
                                    onClick={() => {
                                        navigate(
                                            `../item_group/${itemGroup}/application/${application}/collection/${collection}`
                                        );
                                    }}
                                >
                                    Explore The Collection
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </>
            )}
        </>
    );
}

export default FeaturedGallery;
