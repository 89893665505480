import { Grid, Box, Typography, Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

function CompanyHausGrid({
  data,
  hasTitles,
  hasButtons,
  hasBackgroundText,
  spacingValue,
  hasCollectionLinks,
  header,
  marginTop,
  marginBottom,
  paragraph,
  isParagraphReverse,
  isTextCenter,
  subheader,
  isTextAlign,
}: any) {
  const navigate = useNavigate();
  return (
    <>
      {/* <Container maxWidth='xl'> */}
      <Box sx={{ mt: { marginTop }, mb: { marginBottom } }}>
        <Box sx={{ ml: "4rem", mb: -2 }}>
          <Typography fontWeight="300" variant="h3">
            {header}
          </Typography>
        </Box>
        <Grid container spacing={3} sx={{ marginTop: "30px" }}>
          {data.map((elem: any, index: number) => (
            <Grid key={index} item xs={12} sm={6} md={spacingValue}>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: {
                    xs: "auto",

                    md: "650px",
                  },
                }}
              >
                <img
                  src={elem.img}
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    height: "100%",
                  }}
                  alt="categories"
                />
                {hasBackgroundText && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "2rem",
                        textTransform: "uppercase",
                      }}
                    >
                      {elem.title}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box></Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "1.5rem",
                  paddingX: {xs: '5%', lg:"20%"},

                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isParagraphReverse
                      ? "column-reverse"
                      : "column",
                    // alignItems: isTextCenter ? "center" : "flex-start",
                    alignItems: {xs: 'start', lg: isTextCenter ? "center" : "flex-start"},

                  }}
                >
                  {hasTitles && (
                    <Typography
                      variant="h5"
                      sx={{
                        textAlign: {xs: 'start', lg: 'center'},
                        fontSize: "40px",
                        fontWeight: "500",
                        lineHeight: "27.5px",
                        my: "20px",
                      }}
                    >
                      {elem.title}
                    </Typography>
                  )}
                  <Typography
                    fontWeight="300"
                    sx={{
                      textAlign: {xs: 'start', lg: isTextAlign && "center"},
                      fontSize: "20px",
                    }}
                  >
                    {elem.subTitle || elem.paragraph}
                  </Typography>

                  <Typography fontWeight="300" textAlign="center">
                    {elem.secondaryText || subheader}
                  </Typography>
                </Box>

                {hasButtons ? (
                  elem.title !== "animal attraction" ? (
                    <Button
                      sx={{
                        borderRadius: "1px",
                        padding: "0.5rem 2.5rem",
                        margin: "1.5rem 0rem",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                      variant="outlined"
                      onClick={() => {
                        navigate(elem.link);
                      }}
                    >
                      {elem.buttonText}
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        borderRadius: "1px",
                        padding: "0.5rem 2.5rem",
                        margin: "1.5rem 0rem",
                      }}
                      variant="outlined"
                      onClick={() => {
                        navigate(`../store-locator`);
                      }}
                    >
                      {elem.buttonText}
                    </Button>
                  )
                ) : (
                  <Box></Box>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* </Container> */}
    </>
  );
}

export default CompanyHausGrid;
