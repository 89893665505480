import { Grid, Box, Typography, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MissingContent from '../errorMessages/MissingContent';

function CategoriesGrid({
    data,
    hasTitles,
    hasButtons,
    hasBackgroundText,
    spacingValue,
    hasCollectionLinks,
    marginTop,
    marginBottom,
    hideHomewareRugs,
    hasApplicationType,
}: any) {
    const navigate = useNavigate();

    return (
        <>
            {data === null || data.length === 0 ? (
                <MissingContent sectionName='Item Group Section' />
            ) : (
                <Container maxWidth='xl'>
                    <Grid
                        container
                        rowSpacing={'16px'}
                        columnSpacing={{ xs: 1, sm: 2, md: '16px' }}
                        sx={{ mt: { marginTop }, mb: { marginBottom } }}
                    >
                        {data
                            .filter(
                                (elem: any) =>
                                    elem.item_group_code !== '000' &&
                                    elem.item_group_code !== '004'
                            )
                            .map((elem: any, index: number) => (
                                <Grid
                                    key={index}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={spacingValue}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            cursor: 'pointer',
                                            width: '100%',
                                            height: {
                                                xs: '320px',
                                                lg: '510px',
                                            },
                                            minHeight: '100%',
                                            '&:hover img': {
                                                transform: 'scale(1.03)',
                                            },
                                            overflow: 'hidden',
                                        }}
                                        onClick={() => {
                                            if (hasApplicationType) {
                                                navigate(
                                                    `../item_group/9001/application_type/outdoor/category_name/${elem?.item_group_code}`
                                                );
                                            } else if (hasCollectionLinks) {
                                                navigate(
                                                    `../collections/${elem.url_title}/${elem.id}`
                                                );
                                            } else if (
                                                elem?.item_group === 'wallpaper'
                                            ) {
                                                navigate(
                                                    '../item_group/001/application/hertex wallpaper'
                                                );
                                            } else {
                                                navigate(
                                                    `../item_group/${elem?.item_group_code}`
                                                );
                                            }
                                        }}
                                    >
                                        <img
                                            src={elem?.url}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                background:
                                                    'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.83) 100%)',
                                                transition:
                                                    'transform 0.5s ease',
                                            }}
                                            alt='categories'
                                        />
                                        {hasBackgroundText && (
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    alignItems: 'flex-end',
                                                    justifyContent: 'center',
                                                    background:
                                                        'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)',
                                                }}
                                            >
                                                <Typography
                                                    variant='h6'
                                                    sx={{
                                                        textAlign: 'center',
                                                        fontWeight: 'lighter',
                                                        color: '#fff',
                                                        fontSize: '40px',
                                                        textTransform:
                                                            'capitalize',
                                                        mb: { xs: 3, lg: 4 },
                                                    }}
                                                >
                                                    {elem?.item_group}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            marginTop: '1.5rem',
                                        }}
                                    >
                                        {hasTitles && (
                                            <Typography variant='h5'>
                                                {elem?.item_group}
                                            </Typography>
                                        )}
                                        {hasButtons && (
                                            <Button
                                                sx={{
                                                    borderRadius: '1px',
                                                    padding: '0.5rem 2.5rem',
                                                    margin: '1.5rem 0rem',
                                                }}
                                                variant='outlined'
                                                onClick={() => {
                                                    navigate(
                                                        `../collections/${elem.url_title}/${elem.id},`
                                                    );
                                                }}
                                            >
                                                Explore More
                                            </Button>
                                        )}
                                    </Box>
                                </Grid>
                            ))}
                    </Grid>
                </Container>
            )}
        </>
    );
}

export default CategoriesGrid;
