import { Box, TextField } from '@mui/material';

function FieldArrayInputField({
    defaultValue,
    type,
    id,
    arrayName,
    index,
    placeholder,
    errors,
    register,
    errorMsg,
    validationPattern,
    validationPatternMsg,
    isRequired,
    marginTop,
}: any) {
    return (
        <>
            <TextField
                sx={{ mt: marginTop, width: '100%' }}
                type={type}
                size='small'
                defaultValue={defaultValue}
                id={id}
                placeholder={placeholder}
                {...register(`${arrayName}.${index}.${id}`, {
                    required: isRequired,
                    pattern: validationPattern && {
                        value: validationPattern,
                        message: validationPatternMsg,
                      },
                })}
                error={errors?.[arrayName]?.[index]?.[id] ? true : false}
                helperText={errors?.[arrayName]?.[index]?.[id] ? (errors?.[arrayName]?.[index]?.[id].type === "pattern" ? validationPatternMsg : errorMsg): ""}
            />
        </>
    );
}

export default FieldArrayInputField;
