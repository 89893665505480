import {
    Box,
    Grid,
    ImageList,
    ImageListItem,
    Container,
    Typography,
  } from "@mui/material";
  import MissingContent from "../errorMessages/MissingContent";
  
  function Gallery({
    images,
    galleryLayout,
    title,
    secondaryText,
    hasTitle,
    hasSecondaryText,
    columns,
  }: any) {
    const findImageByPosition = (position: string) => {
      return images.find((img: any) => img.position === position);
    };
  
    return (
      <>
        {" "}
        {images === null || images.length === 0 ? (
          <MissingContent sectionName="Gallery Section" />
        ) : (
          <Container maxWidth="lg">
            <Grid
              mt={3}
              container
              alignItems="center"
              flexDirection="column"
              sx={{ width: "100%" }}
            >
              {hasTitle && <Typography variant="h4">{title}</Typography>}
              {hasSecondaryText && (
                <Typography variant="h6">{secondaryText} </Typography>
              )}
            </Grid>
            <Box
              sx={{
                display: { xs: "none", lg: "block" },
                width: "100%",
                height: "auto",
              }}
            >
              <ImageList variant={galleryLayout} cols={columns} gap={8}>
                {(() => {
                  const image = findImageByPosition("top left");
                  return image ? (
                    <ImageListItem key="top-left" style={{ height: "648px" }}>
                      <img
                        src={`${image.url}`}
                        alt="gallery-top-left"
                        srcSet={`${image.url}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ) : null;
                })()}
  
                {(() => {
                  const image = findImageByPosition("bottom left");
                  return image ? (
                    <ImageListItem key="bottom-left" style={{ height: "366px" }}>
                      <img
                        src={`${image.url}`}
                        alt="gallery-bottom-left"
                        srcSet={`${image.url}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ) : null;
                })()}
  
                {(() => {
                  const image = findImageByPosition("top right");
                  return image ? (
                    <ImageListItem key="top-right" style={{ height: "366px" }}>
                      <img
                        src={`${image.url}`}
                        alt="gallery-top-right"
                        srcSet={`${image.url}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ) : null;
                })()}
  
                {(() => {
                  const image = findImageByPosition("bottom right");
                  return image ? (
                    <ImageListItem key="bottom-right" style={{ height: "648px" }}>
                      <img
                        src={`${image.url}`}
                        alt="gallery-bottom-right"
                        srcSet={`${image.url}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ) : null;
                })()}
              </ImageList>
            </Box>
  
            {/* Mobile View */}
            <Box
              sx={{
                display: { xs: "block", lg: "none" },
                width: "100%",
                height: "auto",
              }}
            >
              <ImageList variant={galleryLayout} cols={1} gap={8}>
                {(() => {
                  const image = findImageByPosition("top left");
                  return image ? (
                    <ImageListItem key="top-left" style={{ height: "auto" }}>
                      <img
                        src={`${image.url}`}
                        alt="gallery-top-left"
                        srcSet={`${image.url}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ) : null;
                })()}
  
                {(() => {
                  const image = findImageByPosition("bottom left");
                  return image ? (
                    <ImageListItem key="bottom-left" style={{ height: "auto" }}>
                      <img
                        src={`${image.url}`}
                        alt="gallery-bottom-left"
                        srcSet={`${image.url}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ) : null;
                })()}
  
                {(() => {
                  const image = findImageByPosition("top right");
                  return image ? (
                    <ImageListItem key="top-right" style={{ height: "auto" }}>
                      <img
                        src={`${image.url}`}
                        alt="gallery-top-right"
                        srcSet={`${image.url}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ) : null;
                })()}
  
                {(() => {
                  const image = findImageByPosition("bottom right");
                  return image ? (
                    <ImageListItem key="bottom-right" style={{ height: "auto" }}>
                      <img
                        src={`${image.url}`}
                        alt="gallery-bottom-right"
                        srcSet={`${image.url}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ) : null;
                })()}
              </ImageList>
            </Box>
          </Container>
        )}
      </>
    );
  }
  
  export default Gallery;
  