import interioVideo from '../assets/home-barners/videos/interioVideo.mp4';
import Hero_1 from '../assets/home-barners/Hero_1.jpg';
import Hero_2 from '../assets/home-barners/Hero_2.jpg';
import Hero_3 from '../assets/home-barners/Hero_3.jpg';

import fabricImage from '../assets/categories-grid/1_Fabric.jpg';
import WallpaperImage from '../assets/categories-grid/2_Wallpaper.jpg';
import HomeImage from '../assets/categories-grid/3_Home.jpg';
import hertexLogo from '../assets/logos/hertex-logo-long.png';
import featured_1 from '../assets/featured-collection/Featured_1.jpg';
import featured_2 from '../assets/featured-collection/Featured_2.jpg';
import featured_3 from '../assets/featured-collection/Featured_3.jpg';

import featured1 from '../assets/featured-collection/fabricPage/featured1.jpg';
import featured2 from '../assets/featured-collection/fabricPage/featured2.jpg';
import featured3 from '../assets/featured-collection/fabricPage/featured3.jpg';

import FabricHero_1 from '../assets/fabrics/heroBanner/Hero_1.jpg';
import Fabric_1 from '../assets/categories-grid/1_Fabric.jpg';
import Fabric_2 from '../assets/categories-grid/2_Wallpaper.jpg';
import Fabric_3 from '../assets/categories-grid/3_Home.jpg';

import uphosteryImage from '../assets/fabrics/categories/1_Upholstery.jpg';
import curtainingImage from '../assets/fabrics/categories/2_Curtaining.jpg';
import dualPurposeImage from '../assets/fabrics/categories/3_Dual Purpose.jpg';

import upholsterHeroSlide from '../assets/fabrics/fabric-category-page/a-hero/hero_category.jpg';

// import arrival01 from '../assets/new-arrivals/arrival01.jpg';
// import arrival02 from '../assets/new-arrivals/arrival02.jpg';
// import arrival03 from '../assets/new-arrivals/arrival03.jpg';
// import arrival04 from '../assets/new-arrivals/arrival04.jpg';
// import arrival05 from '../assets/new-arrivals/arrival05.jpg';
import internationalImage from '../assets/internationals_image.png';
import upholstery from '../assets/fabrics/fabric-main-page/category-cards/upholstery.jpeg';
import curtaining from '../assets/fabrics/fabric-main-page/category-cards/curtaining.jpeg';
import dualPurpose from '../assets/fabrics/fabric-main-page/category-cards/dualPurpose.jpeg';
import outdoor from '../assets/fabrics/fabric-main-page/category-cards/outdoor.jpeg';
import easytoclean from '../assets/fabrics/fabric-main-page/category-cards/easyToclean.jpeg';
import earthfriendly from '../assets/fabrics/fabric-main-page/category-cards/earthFriendly.jpeg';

import cosmosImage from '../assets/fabrics/fabric-category-page/all-collections/1.png';
import domusImage from '../assets/fabrics/fabric-category-page/all-collections/2.png';
import dozeyImage from '../assets/fabrics/fabric-category-page/all-collections/3.png';
import elektraImage from '../assets/fabrics/fabric-category-page/all-collections/4.png';

import hydroImage from '../assets/fabrics/fabric-category-page/all-collections/5.png';
import lasVegasImage from '../assets/fabrics/fabric-category-page/all-collections/6.png';
import lavishImage from '../assets/fabrics/fabric-category-page/all-collections/7.png';
import luxuryImage from '../assets/fabrics/fabric-category-page/all-collections/8.png';

import cosmosImageTwo from '../assets/fabrics/fabric-category-page/all-collections/9.png';
import domusImageTwo from '../assets/fabrics/fabric-category-page/all-collections/10.png';
import dozeyImageTwo from '../assets/fabrics/fabric-category-page/all-collections/11.png';
import elektraImageTwo from '../assets/fabrics/fabric-category-page/all-collections/12.png';

import hydroImageTwo from '../assets/fabrics/fabric-category-page/all-collections/13.png';
import lasVegasImageTwo from '../assets/fabrics/fabric-category-page/all-collections/14.png';
import lavishImageTwo from '../assets/fabrics/fabric-category-page/all-collections/15.png';
import luxuryImageTwo from '../assets/fabrics/fabric-category-page/all-collections/16.png';

import bannerCollectionData from '../assets/fabrics/fabric-category-page/all-collections/a-hero/collection_banner.png';

import bamboo from '../assets/fabrics/design-collection/bason/bamboo.png';
import bluesteel from '../assets/fabrics/design-collection/bason/bluesteel.png';
import buck from '../assets/fabrics/design-collection/bason/buck.png';
import carton from '../assets/fabrics/design-collection/bason/carton.png';
import dahila from '../assets/fabrics/design-collection/bason/dahila.png';
import ebony from '../assets/fabrics/design-collection/bason/ebony.png';
import forest from '../assets/fabrics/design-collection/bason/forest.png';
import mink from '../assets/fabrics/design-collection/bason/mink.png';
import oxblood from '../assets/fabrics/design-collection/bason/oxblood.png';
import rosewhite from '../assets/fabrics/design-collection/bason/rosewhite.png';
import sandshell from '../assets/fabrics/design-collection/bason/sandshell.png';
import seafoam from '../assets/fabrics/design-collection/bason/seafoam.png';
import tahina from '../assets/fabrics/design-collection/bason/tahina.png';
import tobacco from '../assets/fabrics/design-collection/bason/tobacco.png';

import frost from '../assets/fabrics/design-collection/javan/frost.png';
import military from '../assets/fabrics/design-collection/javan/military.png';
import mocha from '../assets/fabrics/design-collection/javan/mocha.png';
import obsisian from '../assets/fabrics/design-collection/javan/obsisian.png';
import rhino from '../assets/fabrics/design-collection/javan/rhino.png';
import tan from '../assets/fabrics/design-collection/javan/tan.png';

import colorOne from '../assets/fabrics/colors/Ellipse1.png';
import colorTwo from '../assets/fabrics/colors/Ellipse2.png';
import colorThree from '../assets/fabrics/colors/Ellipse3.png';
import colorFour from '../assets/fabrics/colors/Ellipse4.png';
import colorFive from '../assets/fabrics/colors/Ellipse5.png';
import colorSix from '../assets/fabrics/colors/Ellipse6.png';

import serengeti from '../assets/new-arrivals/01_Serengeti.jpg';
import vow from '../assets/new-arrivals/02_Vow.jpg';
import paarlRock from '../assets/new-arrivals/03_Paarl Rock.jpg';

import tribualImage from '../assets/fabrics/tribual/tribual-treasure.png';
import aspenImage from '../assets/fabrics/tribual/aspen.png';
import adornImage from '../assets/fabrics/tribual/adorn.png';
import mobileImage from '../assets/mobile.png';
import internationalsImage from '../assets/internationals_image.png';
import hausLogo from '../assets/hausLogo.png';

import customerImage from '../assets/company/about/hertex_1.jpg';
import directorImages from '../assets/directors.png';

import hausImage from '../assets/company/about/hertex_2.jpg';

import responsibilityImage from '../assets/company/about/Rectangle 36.jpg';
import categoryImage from '../assets/Category.png';
import directorsImagesTwo from '../assets/directorsImgsTwo.png';
import tradeImage from '../assets/trade_image.jpeg';
import vidaImage from '../assets/vida.png';
import showroomImage from '../assets/company/explore/explore_1.jpg';
import storesImage from '../assets/company/explore/explore_2.jpg';
import caffeImage from '../assets/company/explore/explore_3.jpg';

import lemieux from '../assets/internationals/newCatalogues/HXI_Lemieux Et Cie_York_ISSUU Pages_Cover.jpg';
import grassclothNatural from '../assets/internationals/newCatalogues/HXI_Grasscloth & Natural Resources_York_ISSUU Pages_Cover.jpg';
import urbanDigest from '../assets/internationals/newCatalogues/HXI_Urban Digest_York_ISSUU Pages_Cover.jpg';
import breeganJane from '../assets/internationals/newCatalogues/HXI_Breegan Jane, Paradiso, Secret Garden, Evora, Marina_C&C_ISSUU Pages_Cover.jpg';
import vividoUrban from '../assets/internationals/newCatalogues/HXI_Vivido Urban Whitworth_C&C_Cover.jpg';
import alfresco from '../assets/internationals/newCatalogues/HXI_Alfresco William Morris_C&C_Cover.jpg';
import midori from '../assets/internationals/newCatalogues/HXI_Midori_C&C_ISSUU pages_Cover.jpg';
import wallDesigns from '../assets/internationals/newCatalogues/HXI_Wall Designs 4_Masureel_ISSUU Pages_Cover.jpg';
import masureelJoy from '../assets/internationals/newCatalogues/Masureel_Joy.jpg'
import masureelRock from '../assets/internationals/newCatalogues/Masureel_Rock.jpg'
import masureelTerra from '../assets/internationals/newCatalogues/Masureel_Terra.jpg'
import harlequinSophieRobinson from '../assets/internationals/newCatalogues/Harlequin_Sophie Robinson.jpg'
import reflect from '../assets/internationals/newCatalogues/HXI_Reflect_Harlequin_Cover.jpg';
import sheers1 from '../assets/internationals/newCatalogues/HXI_Sheers_Harlequin_ISSUU Pages_Cover.jpg';
import editeurLinenQuarter from '../assets/internationals/newCatalogues/Editeur_Linen Quarter.jpg'
import carnabyStreet from '../assets/internationals/newCatalogues/HXI_Carnaby Street_Cover.jpg';
import gilesDeacon from '../assets/internationals/newCatalogues/HXI_Giles Deacon_Sanderson_ISSUU Pages_Cover.jpg';
import perfectPlains from '../assets/internationals/newCatalogues/HXI_Perfect Plains_Brewster_Cover.jpg';


export const vidaImg = [
    {
        img: vidaImage,
    },
];
export const HertexLogo = [
    {
        img: hertexLogo,
    },
];

export const showroomsImg = [
    {
        img: showroomImage,
    },
];
export const storesImg = [
    {
        img: storesImage,
    },
];

export const caffeImg = [
    {
        img: caffeImage,
    },
];

export const trade = [
    {
        img: tradeImage,
    },
];

export const directorsPageImages = [
    {
        img: directorsImagesTwo,
        title: 'Name Surname',
        subTitle: 'DESIGNATION',
        secondaryText:
            'lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas 1',
        id: '1',
    },
    {
        img: directorsImagesTwo,
        title: 'Name Surname',
        subTitle: 'DESIGNATION',
        secondaryText:
            'lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas 2',
        id: '2',
    },
    {
        img: directorsImagesTwo,
        title: 'Name Surname',
        subTitle: 'DESIGNATION',
        secondaryText:
            'lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas 3',
        id: '3',
    },
];

export const internationals = [
    {
        img: internationalsImage,
    },
];

export const mobileImgs = [
    {
        img: mobileImage,
    },
];

export const customerImgs = [
    {
        img: customerImage,
    },
];

export const hausImgs = [
    {
        img: hausImage,
    },
];

export const responsibilityImgs = [
    {
        img: responsibilityImage,
    },
];

export const hausLogoImg = [
    {
        img: hausLogo,
    },
];

export const aboutUsData = [
    {
        img: categoryImage,
        title: 'Our Partners',
        buttonText: 'VIEW NOW',
        paragraph:
            'Founded by Hertex, HAUS was created to give customers instant access to an expertly curated collection of homeware to complete their interior schemes',
        id: '1',
        link: '../directors',
    },
    {
        img: categoryImage,
        paragraph:
            'Founded by Hertex, HAUS was created to give customers instant access to an expertly curated collection of homeware to complete their interior schemes',
        buttonText: 'FIND A STORE',

        title: 'Explore Our Spaces',
        id: '2',
        link: '../store-locator',
    },
];

export const featuredHomeCollectionList = [
    {
        img: 'https://images.unsplash.com/photo-1531877264583-a58acd888a97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
        title: 'Featured Wall Covering 1',
        url_title: 'featured_wall_covering_1',
        secondaryText: 'Secondary 1',
        id: '1',
    },
    {
        img: 'https://images.unsplash.com/photo-1591195854242-8804547cdcab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
        title: 'Featured Wall Covering 2',
        url_title: 'featured_wall_covering_2',
        secondaryText: 'Secondary 2',
        id: '2',
    },
    {
        img: 'https://images.unsplash.com/photo-1605635543678-af6dd29fad9e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1476&q=80',
        title: 'Featured Wall Covering 3',
        url_title: 'featured_wall_covering_3',
        secondaryText: 'Secondary 3',
        id: '3',
    },
];

export const newCataloguesBookData = [
    { img: 'bookImg', title: 'animal attraction' },
    { img: 'bookImg', title: 'animal attraction' },
    { img: 'bookImg', title: 'animal attraction' },
];

export const directorImgs = [
    {
        img: directorImages,
    },
];

export const carouselImages = [
    {
        url: 'https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    },
    {
        url: 'https://images.unsplash.com/photo-1616486338812-3dadae4b4ace?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80',
    },
    {
        url: 'https://images.unsplash.com/photo-1556228453-efd6c1ff04f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    },
    {
        url: 'https://images.unsplash.com/photo-1540574163026-643ea20ade25?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    },
    {
        url: 'https://images.unsplash.com/photo-1600121848594-d8644e57abab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    },
];

export const carouselVideos = [
    {
        id: 1,
        title: 'Welcome',
        src: 'carouselVideo',
        description: 'To the Hertex website',
    },
];

export const categoryImages = [
    {
        img: 'fabricCategoryImg',
        title: 'Fabrics',
        item_group: '005',
        url_title: 'fabric',
        secondaryText: 'Secondary 1',
        id: '1',
    },
    {
        img: 'wallpaperCategoryImg',
        title: 'Wall Coverings',
        item_group: '001',
        url_title: 'wallpaper',
        secondaryText: 'Secondary 2',
        id: '2',
    },
    {
        img: 'rugsCategoryImg',
        title: 'Rugs',
        item_group: '004',
        url_title: 'rugs',
        secondaryText: 'Secondary 3',
        id: '3',
    },
    {
        img: 'homeCategoryImg',
        title: 'Homeware',
        item_group: '005',
        url_title: 'homeware',
        secondaryText: 'Secondary 3',
        id: '4',
    },
];

export const uniformGallery = [
    {
        img: 'https://images.unsplash.com/photo-1598928506311-c55ded91a20c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGludGVyaW9yJTIwZGVzaWdufGVufDB8MHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
    },
    {
        img: 'https://images.unsplash.com/photo-1618221710640-c0eaaa2adb49?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1618221469555-7f3ad97540d6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1633110187937-6e3b2f36dfca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1617104424032-b9bd6972d0e4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1092&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1617104611622-d5f245d317f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1184&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1618220924273-338d82d6b886?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1542928658-22251e208ac1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1617104678098-de229db51175?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1214&q=80',
    },
];

export const fabricCategoriesData = [
    { img: uphosteryImage },
    { img: curtainingImage },
    { img: dualPurposeImage },
];

export const categoriesImages = [
    { img: fabricImage },
    { img: WallpaperImage },
    { img: HomeImage },
];

export const tribualFabricData = [
    { img: tribualImage },
    { img: aspenImage },
    { img: adornImage },
];

export const carouselHomeData = [
    { img: Hero_1, homePageTitle: 'The element Collection' },
    { img: Hero_2 },
    { img: Hero_3 },
];

export const newArrivalImagesData = [
    {
        img: serengeti,
        title: 'Serengeti',
        id: '1',
    },
    {
        img: vow,
        title: 'Vow',
        id: '2',
    },
    {
        img: paarlRock,
        title: 'Paarl Rock',
        id: '3',
    },
];

export const featureCollectionData = [
    { collectionName: 'Emerald Isle', tag: 'Category Tag' },
    { img: featured_1 },
    {
        img: 'https://images.unsplash.com/photo-1546448396-6aef80193ceb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80',
        tag: 'Category Tag',
        name: 'Collection Name',
        text: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.',
    },
    { img: featured_2 },
    { img: featured_3 },
];
export const featureFabricCollectionData = [
    { collectionName: 'Collection Name', tag: 'Category Tag' },
    { img: featured1 },
    {
        img: 'https://images.unsplash.com/photo-1546448396-6aef80193ceb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80',
        tag: 'Category Tag',
        name: 'Collection Name',
        text: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.',
    },
    { img: featured2 },
    { img: featured3 },
];

export const farbricHeroImageData = [
    { img: FabricHero_1, fabricPageTitle: 'Fabrics' },
];

export const categoriesColorsData = [
    { img: bamboo, title: 'fabric name', color: 'color' },
    { img: bamboo, title: 'fabric name', color: 'color' },
    { img: bamboo, title: 'fabric name', color: 'color' },
];

export const colorsCategoriesData = [
    { color: colorOne },
    { color: colorOne },
    { color: colorTwo },
    { color: colorThree },
    { color: colorFour },
    { color: colorFive },
    { color: colorSix },
    // {color: colorPlus},
];

export const categoriesDataList = [
    { item: 'Upholostery' },
    { item: 'Curtaining' },
    { item: 'Dual Purpose' },
    { item: 'Outdoor' },
    { item: 'Easy to clean' },
];

export const companySubMenuData = [
    {
        title: 'about us',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
        subMenuId: 1,
    },
    {
        title: 'our partners',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
        subMenuId: 2,
    },
    {
        title: 'explore out spaces',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
        subMenuId: 3,
    },
    {
        title: 'careers',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
        subMenuId: 4,
    },
    {
        title: 'responsibilities',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
        subMenuId: 4,
    },
];

export const designsCollectionData = [
    { img: bamboo, title: 'bason', subTitle: 'bamboo' },
    { img: bluesteel, title: 'bason', subTitle: 'bluesteel' },
    { img: buck, title: 'bason', subTitle: 'buck' },
    { img: carton, title: 'bason', subTitle: 'carton' },
    { img: dahila, title: 'bason', subTitle: 'dahila' },
    { img: ebony, title: 'bason', subTitle: 'ebony' },
    { img: forest, title: 'bason', subTitle: 'forest' },
    { img: mink, title: 'bason', subTitle: 'mink' },
    { img: oxblood, title: 'bason', subTitle: 'oxblood' },
    { img: rosewhite, title: 'bason', subTitle: 'rosewhite' },
    { img: sandshell, title: 'bason', subTitle: 'sandshell' },
    { img: seafoam, title: 'bason', subTitle: 'seafoam' },
    { img: tahina, title: 'bason', subTitle: 'tahina' },
    { img: tobacco, title: 'bason', subTitle: 'tobacco' },
];

export const designjavaCollectionData = [
    { img: frost, title: 'javan', subTitle: 'frost' },
    { img: military, title: 'javan', subTitle: 'military' },
    { img: mocha, title: 'javan', subTitle: 'mocha' },
    { img: obsisian, title: 'javan', subTitle: 'obsisian' },
    { img: rhino, title: 'javan', subTitle: 'rhino' },
    { img: tan, title: 'javan', subTitle: 'tan' },
];

export const bannerImageData = [{ img: upholsterHeroSlide }];

export const collectionBannerData = [{ img: bannerCollectionData }];

export const upholsteryImagesData = [
    { img: cosmosImage, title: 'cosmos' },
    { img: domusImage, title: 'domus' },
    { img: dozeyImage, title: 'dosey' },
    { img: elektraImage, title: 'elektra' },

    { img: hydroImage, title: 'hydro' },
    { img: lasVegasImage, title: 'lavegas' },
    { img: lavishImage, title: 'lavish' },
    { img: luxuryImage, title: 'luxury outdoor velvet' },

    { img: cosmosImageTwo, title: 'cosmos' },
    { img: domusImageTwo, title: 'domus' },
    { img: dozeyImageTwo, title: 'dozey' },
    { img: elektraImageTwo, title: 'elektra' },

    { img: hydroImageTwo, title: 'elektra' },
    { img: lasVegasImageTwo, title: 'lasvegas' },
    { img: lavishImageTwo, title: 'lavish' },
    { img: luxuryImageTwo, title: 'luxury outdoor velvet' },
];

export const brandsFabricData = [
    { title: 'Hertex' },
    { title: 'Care Free' },
    { title: 'Stonehaus' },
    { title: 'Couture' },
    { title: 'Studio H' },
    { title: 'Internationals' },
];

export const categoryCardData = [
    { img: upholstery, title: 'upholstery' },
    // { img: curtaining, title: "curtaining" },
    // { img: dualPurpose, title: "dual purpose" },
    // { img: outdoor, title: "outdoor" },
    // { img: easytoclean, title: "easy to clean" },
    // { img: earthfriendly, title: "earth friendly" },
];

export const fabricsSubMenuData = [
    {
        title: 'upholstery',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
    },
    {
        title: 'dual purpose',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
    },
    {
        title: 'curtaining',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
    },
    {
        title: 'outdoor',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
    },
    {
        title: 'easy to clean',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
    },
];

export const newArrivalsCorouselData = [
    {
        img: 'https://images.unsplash.com/photo-1598928506311-c55ded91a20c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGludGVyaW9yJTIwZGVzaWdufGVufDB8MHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
    },
    {
        img: 'https://images.unsplash.com/photo-1618221710640-c0eaaa2adb49?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1618221469555-7f3ad97540d6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1633110187937-6e3b2f36dfca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1617104424032-b9bd6972d0e4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1092&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1617104611622-d5f245d317f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1184&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1618220924273-338d82d6b886?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1542928658-22251e208ac1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80',
    },
    {
        img: 'https://images.unsplash.com/photo-1617104678098-de229db51175?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1214&q=80',
    },
];
export const carouselVideosData = [
    {
        id: 1,
        title: 'Welcome',
        src: interioVideo,
        description: 'To Hertex',
    },
];

export const brandsData = [
    { title: 'Upholstery' },
    { title: 'Curtaining' },
    { title: 'Dual purpose' },
    { title: 'Outdoor' },
    { title: 'Easy to clean' },
    { title: 'Earth friendly' },
];

export const internationalData = [
    {
        img: internationalImage,
    },
];

export const catalogueData = [
    {id: '01', img: lemieux, url:'https://issuu.com/hertexhaus/docs/hxi_lemieux_et_cie_york_issuu_pages?fr=xKAE9_zU1NQ' },
    {id: '02', img: grassclothNatural, url:'https://issuu.com/hertexhaus/docs/hxi_grasscloth_natural_resources_york_issuu_page?fr=xKAE9_zU1NQ' },
    {id: '03', img: urbanDigest, url:'https://issuu.com/hertexhaus/docs/hxi_urban_digest_york_issuu_pages?fr=xKAE9_zU1NQ' }, 
    {id: '04', img: breeganJane, url:'https://issuu.com/hertexhaus/docs/hxi_breegan_jane_paradiso_secret_garden_evora_?fr=xKAE9_zU1NQ' }, 
    {id: '05', img: vividoUrban, url:'https://issuu.com/hertexhaus/docs/hxi_vivido_urban_whitworth_c_c_issuu_pages?fr=xKAE9_zU1NQ' },  
    {id: '06', img: alfresco, url:'https://issuu.com/hertexhaus/docs/hxi_alfresco_william_morris_c_c_issuu_pages?fr=xKAE9_zU1NQ' },
    {id: '07', img: midori, url:'https://issuu.com/hertexhaus/docs/hxi_midori_c_c_issuu_pages?fr=xKAE9_zU1NQ' },
    {id: '08', img: wallDesigns, url:'https://issuu.com/hertexhaus/docs/hxi_wall_designs_4_masureel_issuu_pages?fr=xKAE9_zU1NQ' },  
    {id: '09', img: masureelJoy, url:'https://issuu.com/hertexhaus/docs/hxi_joy_masureel_issuu_pages?fr=xKAE9_zU1NQ' },  
    {id: '10', img: masureelRock, url:'https://issuu.com/hertexhaus/docs/hxi_rock_masureel_issuu_pages?fr=xKAE9_zU1NQ' },  
    {id: '11', img: masureelTerra, url:'https://issuu.com/hertexhaus/docs/hxi_terra_masureel_issuu_pages?fr=xKAE9_zU1NQ' },  
    {id: '12', img: harlequinSophieRobinson, url:'https://issuu.com/hertexhaus/docs/hxi_sophie_robinson_harlequin_issuu_pages?fr=xKAE9_zU1NQ' }, 
    {id: '13', img: reflect, url:'https://issuu.com/hertexhaus/docs/hxi_reflect_harlequin_issuu_pages?fr=xKAE9_zU1NQ' }, 
    {id: '14', img: sheers1, url:'https://issuu.com/hertexhaus/docs/hxi_sheers_harlequin_issuu_pages?fr=xKAE9_zU1NQ' }, 
    {id: '15', img: editeurLinenQuarter, url:'https://issuu.com/hertexhaus/docs/hx_linen_quarter_issuu_pages?fr=xKAE9_zU1NQ' },
    {id: '16', img: carnabyStreet, url:'https://issuu.com/hertexhaus/docs/hx_carnaby_street_issuu_pages?fr=xKAE9_zU1NQ' }, 
    {id: '17', img: gilesDeacon, url:'https://issuu.com/hertexhaus/docs/hxi_giles_deacon_sanderson_issuu_pages?fr=xKAE9_zU1NQ' }, 
    {id: '18', img: perfectPlains, url:'https://issuu.com/hertexhaus/docs/hxi_perfect_plains_brewster_issuu_pages?fr=xKAE9_zU1NQ' },
];


