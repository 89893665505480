import { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import InputField from '../../../components/shared/formFields/InputField';
import SelectField from '../../../components/shared/formFields/SelectField';
import FieldArrayInputField from '../../../components/shared/formFields/FieldArrayInputField';
import AddIcon from '@mui/icons-material/Add';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

import {
    Grid,
    Box,
    Typography,
    Paper,
    Stack,
    Button,
    Divider,
    FormControlLabel,
    FormGroup,
    Switch,
    Checkbox,
} from '@mui/material';
import { validationPatterns } from '../../../utilities/ValidtionPatterns';

function CompanyDetails({
    updateStep,
    formData,
    setFormData,
    previousStep,
}: any) {
    const [offeringValue, setOfferingValue] = useState('fabric_and_homeware');

    const [salesRepresentative, setSalesRepresentative] = useState('yes');

    const [isPhysicalAddress, setIsPhysicalAddress] = useState<boolean>(
        formData?.company_details?.is_physical_address ?? true
    );

    const [physicalAddress, setPhysicalAddress] = useState<string>('');

    const [isAppended, setIsAppended] = useState<boolean>(false);

    const [partnerFieldsIsAppended, setPartnerFieldsIsAppended] =
        useState<boolean>(false);

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    }: any = useForm<any | Error>({});

    const formSubmit = handleSubmit((data: any, e: any) => {
        e?.preventDefault();

        const dataStructure = {
            offering: offeringValue,
            sales_representative: salesRepresentative,
            company_name: data.companyName,
            trading_as: data.tradingAs,
            trading_since: data.tradingSince,
            company_registration_number: data.regNumber,
            vat_number: data.vatNumber,
            owner_name: data.ownerName,
            entity_type: data.entityType,
            industry: Array.isArray(data.industry)
                ? data.industry
                : data.industry.split(',').map((item: string) => item.trim()),
            physical_address: data.physicalAddress,
            postal_address: isPhysicalAddress
                ? data.physicalAddress
                : data.postalAddress,
            is_physical_address: isPhysicalAddress,
            bank_name: data.bankName,
            account_number: data.accountNumber,
            branch_code: data.branchCode,
            partners: data.partner,
            contacts: data.contact,
        };

        setFormData({
            ...formData,
            company_details: dataStructure,
        });

        updateStep();
    });

    const {
        fields: contactFields,
        append: contactFieldsAppend,
        remove: contactFieldsRemove,
    } = useFieldArray({
        control,
        name: 'contact',
    });

    const {
        fields: partnerFields,
        append: partnerFieldsAppend,
        remove: partnerFieldsRemove,
    } = useFieldArray({
        control,
        name: 'partner',
    });

    // const handleOfferingChange = (
    //     event: React.MouseEvent<HTMLElement>,
    //     newTradeMemberValue: string
    // ) => {
    //     setOfferingValue(newTradeMemberValue);
    // };

    const handleOfferingChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setOfferingValue(event.target.value);
    };

    const handleRepresentativeChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSalesRepresentative(event.target.value);
    };

    useEffect(() => {
        if (contactFields.length > 0) {
            setIsAppended(true);
        } else {
            setIsAppended(false);
        }
    }, [contactFields]);

    useEffect(() => {
        if (partnerFields.length > 0) {
            setPartnerFieldsIsAppended(true);
        } else {
            setPartnerFieldsIsAppended(false);
        }
    }, [partnerFields]);

    return (
        <Grid mt={9} sx={{ mb: { xs: '20px', lg: '0' } }}>
            <Typography
                sx={{
                    display: { xs: 'none', lg: 'flex' },
                    fontSize: '32px',
                    fontWeight: 'bold',
                    lineHeight: '33px',
                    textTransform: 'capitalize',
                    color: '#000',
                }}
            >
                Company Details{' '}
            </Typography>

            <Typography
                sx={{
                    display: { xs: 'none', lg: 'flex' },
                    fontSize: '16px',
                    fontStyle: 'italic',
                    fontWeight: '500',
                    lineHeight: '20px',
                    color: '#000',
                    mt: '20px',
                }}
            >
                Please note that submitting this application form does not
                guarantee approval or opening of a trade account, as all
                applications are subject to review and approval by our team.
            </Typography>

            <Typography
                sx={{
                    display: { xs: 'none', lg: 'flex' },
                    fontSize: '16px',
                    fontWeight: 'bold',
                    lineHeight: '20px',
                    color: '#000',
                    mt: '30px',
                }}
            >
                * Indicates a required field
            </Typography>
            <Paper elevation={1} sx={{ mt: 5, p: 3 }}>
                <Stack>
                    <Typography variant='caption'>
                        Please select a Hertex offering that would be most
                        applicable to you
                    </Typography>

                    {/* <ToggleButtonGroup
                        size='small'
                        color='primary'
                        value={offeringValue}
                        exclusive
                        onChange={handleRepresentativeChange}
                        aria-label='Platform'
                        sx={{ mt: 2 }}
                    >
                        <ToggleButton value='fabric_and_homeware'>
                            Fabric & Homeware
                        </ToggleButton>
                        <ToggleButton value='homeware_only'>
                            Homeware only
                        </ToggleButton>
                    </ToggleButtonGroup> */}

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', lg: 'row' },
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        offeringValue === 'fabric_and_homeware'
                                    }
                                    onChange={handleOfferingChange}
                                    value='fabric_and_homeware'
                                />
                            }
                            label={
                                <Typography variant='caption'>
                                    Fabric & Homeware
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={offeringValue === 'homeware_only'}
                                    onChange={handleOfferingChange}
                                    value='homeware_only'
                                />
                            }
                            label={
                                <Typography variant='caption'>
                                    Homeware only
                                </Typography>
                            }
                        />
                    </Box>
                </Stack>
                <Stack>
                    <Typography variant='caption'>
                        Would you like a sales representative to call on you?
                    </Typography>

                    {/* <ToggleButtonGroup
                        size='small'
                        color='primary'
                        value={offeringValue}
                        exclusive
                        onChange={handleOfferingChange}
                        aria-label='Platform'
                        sx={{ mt: 2 }}
                    >
                        <ToggleButton value='fabric_and_homeware'>
                            Fabric & Homeware
                        </ToggleButton>
                        <ToggleButton value='homeware_only'>
                            Homeware only
                        </ToggleButton>
                    </ToggleButtonGroup> */}

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', lg: 'row' },
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={salesRepresentative === 'yes'}
                                    onChange={handleRepresentativeChange}
                                    value='yes'
                                />
                            }
                            label={
                                <Typography variant='caption'>Yes</Typography>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={salesRepresentative === 'no'}
                                    onChange={handleRepresentativeChange}
                                    value='no'
                                />
                            }
                            label={
                                <Typography variant='caption'>No</Typography>
                            }
                        />
                    </Box>
                </Stack>
            </Paper>

            <form onSubmit={formSubmit} noValidate>
                <Paper elevation={1} sx={{ mt: 5, p: 3 }}>
                    <Stack>
                        <Typography
                            variant='caption'
                            sx={{
                                display: { xs: 'block', lg: 'none' },
                                fontWeight: '700',
                                color: '#000',
                                fontSize: '20px',
                            }}
                        >
                            Company Particulars
                        </Typography>
                        <Typography variant='caption'>
                            * Registered name of Company
                        </Typography>
                        <Box sx={{ width: { xs: '100%', lg: '48.5%' } }}>
                            <InputField
                                defaultValue={
                                    formData?.company_details?.company_name
                                }
                                marginTop={1}
                                type='text'
                                id='companyName'
                                name='companyName'
                                placeholder='Enter company name'
                                isRequired={true}
                                register={register}
                                errors={errors}
                                errorMsg='Please enter the company name'
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                mt: 2,
                                flexDirection: { xs: 'column', lg: 'row' },
                                alignItems: 'end',
                            }}
                        >
                            <Stack
                                sx={{
                                    width: { xs: '100%', lg: '50%' },
                                    mr: { xs: 0, lg: 2 },
                                }}
                            >
                                <Typography variant='caption'>
                                    * Trading as
                                </Typography>
                                <InputField
                                    defaultValue={
                                        formData?.company_details?.trading_as
                                    }
                                    marginTop={1}
                                    type='text'
                                    id='tradingAs'
                                    name='tradingAs'
                                    placeholder='Trading as'
                                    isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter definition'
                                />
                            </Stack>
                            <Stack
                                sx={{
                                    width: { xs: '100%', lg: '50%' },
                                    ml: { xs: 0, lg: 2 },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        fontFamily: 'Inter, sans-serif',
                                        color: '#344054',
                                    }}
                                >
                                    * Trading since
                                </Typography>
                                <InputField
                                    defaultValue={
                                        formData?.company_details?.trading_since
                                    }
                                    register={register}
                                    id='tradingSince'
                                    name='tradingSince'
                                    type='text'
                                    marginTop={1}
                                    placeholder='Year'
                                    isRequired={true}
                                    errors={errors}
                                    errorMsg='Please enter a year'
                                    validationPattern={validationPatterns.year}
                                    validationPatternMsg='Please enter a valid year'
                                />
                            </Stack>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                mt: 2,
                                flexDirection: { xs: 'column', lg: 'row' },
                                alignItems: 'end',
                            }}
                        >
                            <Stack
                                sx={{
                                    width: { xs: '100%', lg: '50%' },
                                    mr: { xs: '0', lg: 2 },
                                }}
                            >
                                <Typography variant='caption'>
                                    Company or CC registration number
                                </Typography>
                                <InputField
                                    defaultValue={
                                        formData?.company_details
                                            ?.company_registration_number
                                    }
                                    marginTop={1}
                                    type='text'
                                    id='regNumber'
                                    name='regNumber'
                                    placeholder='Enter registration number'
                                    isRequired={false}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter the registration number'
                                />
                            </Stack>
                            <Stack
                                sx={{
                                    width: { xs: '100%', lg: '50%' },
                                    ml: { xs: '0', lg: 2 },
                                }}
                            >
                                <Typography variant='caption'>
                                    VAT No
                                </Typography>
                                <InputField
                                    defaultValue={
                                        formData?.company_details?.vat_number
                                    }
                                    type='text'
                                    id='vatNumber'
                                    name='vatNumber'
                                    placeholder='Enter vat number'
                                    isRequired={false}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter the vat number'
                                />
                            </Stack>
                        </Box>

                        <Typography mt={2} variant='caption'>
                            * Owners full name
                        </Typography>
                        <Box sx={{ width: { xs: '100%', lg: '48.5%' } }}>
                            <InputField
                                defaultValue={
                                    formData?.company_details?.owner_name
                                }
                                marginTop={1}
                                type='text'
                                id='ownerName'
                                name='ownerName'
                                placeholder='Enter name'
                                isRequired={true}
                                register={register}
                                errors={errors}
                                errorMsg={`Please enter the owner's name`}
                            />
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                mt: 2,
                                flexDirection: { xs: 'column', lg: 'row' },
                                alignItems: 'end',
                            }}
                        >
                            <Stack
                                sx={{
                                    width: { xs: '100%', lg: '50%' },
                                    mr: { xs: '0', lg: 2 },
                                }}
                            >
                                <Typography variant='caption'>
                                    Type of legal entity
                                </Typography>
                                <SelectField
                                    register={register}
                                    id='entityType'
                                    name='entityType'
                                    itemList={[
                                        { name: '(Pty)Ltd' },
                                        { name: 'Sole Proprietor' },
                                    ]}
                                    defaultValue={
                                        formData?.company_details
                                            ?.entity_type || []
                                    }
                                    marginTop={1}
                                    label='Entity type'
                                    isRequired={false}
                                    errors={errors}
                                    errorMsg='Please select a type'
                                />
                            </Stack>
                            <Stack
                                sx={{
                                    width: { xs: '100%', lg: '50%' },
                                    ml: { xs: '0', lg: 2 },
                                }}
                            >
                                <Typography variant='caption'>
                                    * Industry
                                </Typography>
                                <SelectField
                                    register={register}
                                    id='industry'
                                    name='industry'
                                    itemList={[
                                        { name: 'Architect' },
                                        { name: 'Curtain Workshop' },
                                        { name: 'Decorator' },
                                        { name: 'Designer' },
                                        { name: 'Furniture/Homeware Retailer' },
                                        { name: 'Procurement Company' },
                                        { name: 'Related Export Company' },
                                        { name: 'Specifier' },
                                        { name: 'Stockist' },
                                        {
                                            name: 'Upholsterer/Furniture Manufacturer',
                                        },
                                    ]}
                                    defaultValue={
                                        formData?.company_details?.industry ||
                                        []
                                    }
                                    label='Industry'
                                    isRequired={true}
                                    errors={errors}
                                    errorMsg='Please select an industry'
                                    isMultiSelect={true}
                                />
                            </Stack>
                        </Box>
                        <Divider sx={{ mt: 4, mb: 4 }} />

                        {/* Company Address */}
                        <Typography
                            mb={2}
                            variant='caption'
                            sx={{
                                display: { xs: 'none', lg: 'block' },
                            }}
                        >
                            Company Address
                        </Typography>
                        <Typography
                            variant='caption'
                            sx={{
                                display: { xs: 'block', lg: 'none' },
                                fontWeight: '700',
                                color: '#000',
                                fontSize: '20px',
                            }}
                        >
                            Company Address
                        </Typography>
                        <Box
                            sx={{
                                width: { xs: '100%', lg: '48.5%' },
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Stack>
                                <Typography variant='caption'>
                                    Physical address
                                </Typography>
                                {/* <TextField
                                    sx={{ mt: 1 }}
                                    size='small'
                                    id='physicalAddress'
                                    placeholder='Enter physical address'
                                    {...register('physicalAddress', {
                                        required: true,
                                    })}
                                    error={
                                        errors.physicalAddress ? true : false
                                    }
                                    helperText={
                                        errors.physicalAddress
                                            ? 'Please enter the physical address'
                                            : ''
                                    }
                                    onChange={(e) => {
                                        setPhysicalAddress(e.target.value);
                                    }}
                                /> */}
                                <InputField
                                    defaultValue={
                                        formData?.company_details
                                            ?.physical_address
                                    }
                                    marginTop={1}
                                    type='text'
                                    id='physicalAddress'
                                    name='physicalAddress'
                                    placeholder='Enter physical address'
                                    isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter the physical address'
                                />
                            </Stack>

                            <Stack mt={2}>
                                <Typography variant='caption'>
                                    Postal address
                                </Typography>
                                <InputField
                                    defaultValue={
                                        formData?.company_details
                                            ?.is_physical_address
                                            ? formData?.company_details
                                                  ?.physical_address
                                            : formData?.company_details
                                                  ?.postal_address
                                    }
                                    marginTop={1}
                                    type='text'
                                    id='postalAddress'
                                    name='postalAddress'
                                    placeholder='Enter postal address'
                                    isRequired={
                                        !isPhysicalAddress ? true : false
                                    }
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter the postal address'
                                    disabled={isPhysicalAddress}
                                />
                            </Stack>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch checked={isPhysicalAddress} />
                                    }
                                    label={
                                        <Typography variant='caption'>
                                            Same as physical address
                                        </Typography>
                                    }
                                    onChange={() => {
                                        setIsPhysicalAddress(
                                            !isPhysicalAddress
                                        );
                                        if (!isPhysicalAddress) {
                                            if (!isPhysicalAddress) {
                                                setFormData({
                                                    ...formData,
                                                    company_details: {
                                                        ...formData.company_details,
                                                        postal_address:
                                                            formData
                                                                .company_details
                                                                .physical_address,
                                                    },
                                                });
                                            }
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Box>
                        <Divider sx={{ mt: 4, mb: 4 }} />

                        {/* Contact Person Field Array*/}
                        <Typography
                            variant='caption'
                            sx={{
                                display: { xs: 'none', lg: 'block' },
                            }}
                        >
                            Contact Person/s for Account
                        </Typography>
                        <Typography
                            variant='caption'
                            sx={{
                                display: { xs: 'block', lg: 'none' },
                                fontWeight: '700',
                                color: '#000',
                                fontSize: '20px',
                            }}
                        >
                            Contact Person/s for Account
                        </Typography>
                        {!isAppended && (
                            <Grid container justifyContent='end'>
                                <Button
                                    disableElevation
                                    variant='contained'
                                    onClick={() => contactFieldsAppend({})}
                                    sx={{
                                        textTransform: 'capitalize',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        fontFamily: 'Inter, sans-serif',
                                        color: '#FFF',
                                    }}
                                >
                                    Add Contact
                                </Button>
                            </Grid>
                        )}

                        {contactFields.map((field: any, index) => (
                            <Box key={field.id}>
                                <Stack
                                    sx={{
                                        width: { xs: '100%', lg: '48.5%' },
                                        mr: { xs: 0, lg: 2 },
                                        mt: { xs: 2, lg: 2 },
                                    }}
                                >
                                    <Typography variant='caption'>
                                        Primary Contact
                                    </Typography>
                                    <FieldArrayInputField
                                        defaultValue={
                                            Array.isArray(
                                                formData?.company_details
                                                    ?.contacts
                                            )
                                                ? formData?.company_details
                                                      ?.contacts[index]
                                                      ?.primaryContact || ''
                                                : ''
                                        }
                                        type='text'
                                        id='primaryContact'
                                        arrayName='contact'
                                        index={index}
                                        placeholder='Enter full name'
                                        errors={errors}
                                        register={register}
                                        errorMsg='Please enter full name'
                                        validationPattern=''
                                        isRequired={true}
                                        marginTop={1}
                                    />
                                </Stack>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        mt: 2,
                                        flexDirection: {
                                            xs: 'column',
                                            lg: 'row',
                                        },
                                    }}
                                >
                                    <Stack
                                        sx={{
                                            width: { xs: '100%', lg: '50%' },
                                            mr: { xs: 0, lg: 2 },
                                        }}
                                    >
                                        <Typography variant='caption'>
                                            * Telephone Number
                                        </Typography>
                                        <FieldArrayInputField
                                            defaultValue={
                                                Array.isArray(
                                                    formData?.company_details
                                                        ?.contacts
                                                )
                                                    ? formData?.company_details
                                                          ?.contacts[index]
                                                          ?.primaryContactTelephone ||
                                                      ''
                                                    : ''
                                            }
                                            type='tel'
                                            name='primaryContactTelephone'
                                            id='primaryContactTelephone'
                                            arrayName='contact'
                                            index={index}
                                            placeholder='Enter telephone number'
                                            errors={errors}
                                            register={register}
                                            errorMsg='Please enter telephone number'
                                            validationPattern={
                                                validationPatterns.tel
                                            }
                                            validationPatternMsg='Please enter a valid telephone number'
                                            isRequired={true}
                                            marginTop={1}
                                        />
                                    </Stack>
                                    <Stack
                                        sx={{
                                            width: { xs: '100%', lg: '50%' },
                                            ml: { xs: 0, lg: 2 },
                                        }}
                                    >
                                        <Typography variant='caption'>
                                            * Cellphone number
                                        </Typography>
                                        <FieldArrayInputField
                                            defaultValue={
                                                Array.isArray(
                                                    formData?.company_details
                                                        ?.contacts
                                                )
                                                    ? formData?.company_details
                                                          ?.contacts[index]
                                                          ?.primaryContactCellphone ||
                                                      ''
                                                    : ''
                                            }
                                            type='tel'
                                            id='primaryContactCellphone'
                                            arrayName='contact'
                                            index={index}
                                            placeholder='Enter cellphone number'
                                            errors={errors}
                                            register={register}
                                            errorMsg='Please enter cellphone number'
                                            validationPattern={
                                                validationPatterns.tel
                                            }
                                            validationPatternMsg='Please enter a valid cellphone number'
                                            isRequired={true}
                                            marginTop={1}
                                        />
                                    </Stack>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        mt: 2,
                                        flexDirection: {
                                            xs: 'column',
                                            lg: 'row',
                                        },
                                    }}
                                >
                                    <Stack
                                        sx={{
                                            width: { xs: '100%', lg: '50%' },
                                            mr: { xs: 0, lg: 2 },
                                        }}
                                    >
                                        <Typography variant='caption'>
                                            {`Account email (invoices, statements)`}
                                        </Typography>
                                        <FieldArrayInputField
                                            defaultValue={
                                                Array.isArray(
                                                    formData?.company_details
                                                        ?.contacts
                                                )
                                                    ? formData?.company_details
                                                          ?.contacts[index]
                                                          ?.accountEmail || ''
                                                    : ''
                                            }
                                            type='email'
                                            id='accountEmail'
                                            name='accountEmail'
                                            arrayName='contact'
                                            index={index}
                                            placeholder='Enter account email'
                                            errors={errors}
                                            register={register}
                                            errorMsg='Please enter email'
                                            validationPattern={
                                                validationPatterns.email
                                            }
                                            validationPatternMsg='Please enter a valid email address'
                                            isRequired={true}
                                            marginTop={1}
                                        />
                                    </Stack>
                                    <Stack
                                        sx={{
                                            width: { xs: '100%', lg: '50%' },
                                            ml: { xs: 0, lg: 2 },
                                        }}
                                    >
                                        <Typography variant='caption'>
                                            {`Sales email (marketing info)`}
                                        </Typography>
                                        <FieldArrayInputField
                                            defaultValue={
                                                Array.isArray(
                                                    formData?.company_details
                                                        ?.contacts
                                                )
                                                    ? formData?.company_details
                                                          ?.contacts[index]
                                                          ?.salesEmail || ''
                                                    : ''
                                            }
                                            type='email'
                                            name='salesEmail'
                                            id='salesEmail'
                                            arrayName='contact'
                                            index={index}
                                            placeholder='Enter sales email'
                                            errors={errors}
                                            register={register}
                                            errorMsg='Please enter email'
                                            validationPattern={
                                                validationPatterns.email
                                            }
                                            validationPatternMsg='Please enter a valid email address'
                                            isRequired={true}
                                            marginTop={1}
                                        />
                                    </Stack>
                                </Box>
                                <Grid
                                    mt={2}
                                    container
                                    justifyContent='flex-end'
                                >
                                    <Button
                                        disableElevation
                                        sx={{ backgroundColor: '#FF3030' }}
                                        variant='contained'
                                        onClick={() =>
                                            contactFieldsRemove(index)
                                        }
                                    >
                                        <CancelPresentationIcon
                                            sx={{ fontSize: '25px' }}
                                        />
                                    </Button>
                                </Grid>
                                <Divider sx={{ mt: 3 }} />
                            </Box>
                        ))}
                    </Stack>
                    {isAppended && (
                        <Grid mt={4} container justifyContent='flex-end'>
                            <Button
                                disableElevation
                                variant='contained'
                                endIcon={<AddIcon />}
                                onClick={() => contactFieldsAppend({})}
                                sx={{
                                    textTransform: 'capitalize',
                                    fontWeight: '500',
                                    fontFamily: 'Inter',
                                }}
                            >
                                Add another
                            </Button>
                        </Grid>
                    )}
                    <Divider sx={{ mt: 4, mb: 4 }} />

                    {/* Banking details */}
                    <Stack>
                        <Typography
                            variant='caption'
                            sx={{
                                display: { xs: 'none', lg: 'block' },
                            }}
                        >
                            Company Banking Details
                        </Typography>
                        <Typography
                            variant='caption'
                            sx={{
                                display: { xs: 'block', lg: 'none' },
                                fontWeight: '700',
                                color: '#000',
                                fontSize: '20px',
                            }}
                        >
                            Company Banking Details{' '}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: { xs: 'column', lg: 'row' },
                            }}
                        >
                            <Stack mt={2}>
                                <Typography variant='caption'>Bank</Typography>
                                <InputField
                                    defaultValue={
                                        formData?.company_details?.bank_name
                                    }
                                    marginTop={1}
                                    type='text'
                                    id='bankName'
                                    name='bankName'
                                    placeholder='Enter bank name'
                                    isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter the bank name'
                                />
                            </Stack>

                            <Stack mt={2}>
                                <Typography variant='caption'>
                                    Account number
                                </Typography>
                                <InputField
                                    defaultValue={
                                        formData?.company_details
                                            ?.account_number
                                    }
                                    marginTop={1}
                                    type='number'
                                    id='accountNumber'
                                    name='accountNumber'
                                    placeholder='Enter account number'
                                    isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter the account number'
                                />
                            </Stack>

                            <Stack mt={2}>
                                <Typography variant='caption'>
                                    Branch code
                                </Typography>
                                <InputField
                                    defaultValue={
                                        formData?.company_details?.branch_code
                                    }
                                    marginTop={1}
                                    type='number'
                                    id='branchCode'
                                    name='branchCode'
                                    placeholder='Enter branch code'
                                    isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter the branch '
                                />
                            </Stack>
                        </Box>
                    </Stack>
                    <Divider sx={{ mt: 4, mb: 4 }} />

                    {/* Partners Field Array */}
                    <Typography
                        variant='caption'
                        sx={{
                            display: { xs: 'none', lg: 'block' },
                        }}
                    >
                        Partners and Directors
                    </Typography>
                    <Typography
                        variant='caption'
                        sx={{
                            display: { xs: 'block', lg: 'none' },
                            fontWeight: '700',
                            color: '#000',
                            fontSize: '20px',
                        }}
                    >
                        Partners and Directors{' '}
                    </Typography>
                    {!partnerFieldsIsAppended && (
                        <Grid mb={5} container justifyContent='end'>
                            <Button
                                disableElevation
                                variant='contained'
                                onClick={() => partnerFieldsAppend({})}
                                sx={{
                                    textTransform: 'capitalize',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    fontFamily: 'Inter, sans-serif',
                                    color: '#FFF',
                                }}
                            >
                                Add Partners and Directors
                            </Button>
                        </Grid>
                    )}

                    {partnerFields.map((field: any, index) => (
                        <Box key={field.id}>
                            <Stack
                                sx={{
                                    width: { xs: '100%', lg: '48.5%' },
                                    mr: { xs: 0, lg: 2 },
                                    mt: 2,
                                }}
                            >
                                <Typography variant='caption'>
                                    Partner Name
                                </Typography>
                                <FieldArrayInputField
                                    defaultValue={
                                        Array.isArray(
                                            formData?.company_details?.partners
                                        )
                                            ? formData?.company_details
                                                  ?.partners[index]
                                                  ?.partnerName || ''
                                            : ''
                                    }
                                    type='text'
                                    id='partnerName'
                                    arrayName='partner'
                                    index={index}
                                    placeholder='Enter full name'
                                    errors={errors}
                                    register={register}
                                    errorMsg='Please enter full name'
                                    validationPattern=''
                                    isRequired={true}
                                    marginTop={1}
                                />
                            </Stack>
                            <Stack
                                sx={{
                                    width: { xs: '100%', lg: '48.5%' },
                                    mr: { xs: 0, lg: 2 },
                                    mt: 2,
                                }}
                            >
                                <Typography variant='caption'>
                                    {' '}
                                    ID number
                                </Typography>
                                <FieldArrayInputField
                                    defaultValue={
                                        Array.isArray(
                                            formData?.company_details?.partners
                                        )
                                            ? formData?.company_details
                                                  ?.partners[index]
                                                  ?.partnerIdNumber || ''
                                            : ''
                                    }
                                    type='number'
                                    id='partnerIdNumber'
                                    arrayName='partner'
                                    index={index}
                                    placeholder='Enter ID number'
                                    errors={errors}
                                    register={register}
                                    errorMsg='Please enter ID number'
                                    validationPattern=''
                                    isRequired={true}
                                    marginTop={1}
                                />
                            </Stack>
                            <Stack
                                sx={{
                                    width: { xs: '100%', lg: '48.5%' },
                                    mr: { xs: 0, lg: 2 },
                                    mt: 2,
                                }}
                            >
                                <Typography variant='caption'>
                                    Residential Address (Not PO Box no)
                                </Typography>
                                <FieldArrayInputField
                                    defaultValue={
                                        Array.isArray(
                                            formData?.company_details?.partners
                                        )
                                            ? formData?.company_details
                                                  ?.partners[index]
                                                  ?.partnerResidentialAddress ||
                                              ''
                                            : ''
                                    }
                                    type='text'
                                    id='partnerResidentialAddress'
                                    arrayName='partner'
                                    index={index}
                                    placeholder='Enter resedential address'
                                    errors={errors}
                                    register={register}
                                    errorMsg='Please enter residential address'
                                    validationPattern=''
                                    isRequired={true}
                                    marginTop={1}
                                />
                            </Stack>

                            <Grid mt={2} container justifyContent='flex-end'>
                                <Button
                                    disableElevation
                                    sx={{ backgroundColor: '#FF3030' }}
                                    variant='contained'
                                    onClick={() => partnerFieldsRemove(index)}
                                >
                                    <CancelPresentationIcon
                                        sx={{ fontSize: '25px' }}
                                    />
                                </Button>
                            </Grid>
                            <Divider sx={{ mt: 3 }} />
                        </Box>
                    ))}
                    {partnerFieldsIsAppended && (
                        <Grid mt={4} container justifyContent='flex-end'>
                            <Button
                                disableElevation
                                variant='contained'
                                endIcon={<AddIcon />}
                                onClick={() => partnerFieldsAppend({})}
                                sx={{
                                    fontFamily: 'Inter',
                                    fontWeight: '500',
                                    textTransform: 'capitalize',
                                }}
                            >
                                Add another
                            </Button>
                        </Grid>
                    )}
                </Paper>
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: { xs: 'center', md: 'end' },
                        alignItems: { xs: 'center', md: 'end' },
                        pr: { xs: '10px', md: '25px' },
                        pl: { xs: '10px', md: '0px' },
                        mt: '-10px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: { xs: '10px', md: '10px' },
                            width: '100%',
                            justifyContent: { xs: 'space-between', md: 'end' },
                            alignItems: { xs: 'space-between', md: 'end' },
                        }}
                    >
                        <Button
                            disableElevation
                            type='button'
                            variant='contained'
                            sx={{
                                mt: 3,
                                mb: 3,
                                width: { xs: '100%', md: '20%' },

                                fontWeight: '500',
                                textTransform: 'capitalize',
                                fontFamily: 'Inter',
                            }}
                            onClick={() => {
                                previousStep();
                            }}
                        >
                            Back
                        </Button>

                        <Button
                            disableElevation
                            type='submit'
                            variant='contained'
                            sx={{
                                mt: 3,
                                mb: 3,
                                width: { xs: '100%', md: '20%' },
                                fontWeight: '500',
                                textTransform: 'capitalize',
                                fontFamily: 'Inter',
                            }}
                        >
                            Continue
                        </Button>
                    </Box>
                </Paper>
            </form>
        </Grid>
    );
}

export default CompanyDetails;
