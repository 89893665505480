import { Grid, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MissingContent from '../errorMessages/MissingContent';

function NewArrivalsGrid({
    data,
    hasTitles,
    hasButtons,
    hasBackgroundText,
    spacingValue,
    hasCollectionLinks,
    header,
    marginTop,
    marginBottom,
}: any) {
    const navigate = useNavigate();

    return (
        <>
            {/* <Container maxWidth='xl'> */}
            {data === null || data.length === 0 ? (
                <MissingContent sectionName='New Arrivals' />
            ) : (
                <Box sx={{ mt: { marginTop }, mb: { marginBottom } }}>
                    <Box
                        sx={{
                            ml: { xs: '0', lg: '4rem' },
                            mb: -2,
                            pl: { xs: '5%', lg: '0' },
                        }}
                    >
                        <Typography
                            fontWeight='300'
                            variant='h3'
                            fontSize={'56px'}
                        >
                            {header}
                        </Typography>
                    </Box>

                    <Grid container spacing={'16px'} sx={{ marginTop: '30px' }}>
                        {data?.map((elem: any, index: number) => (
                            <Grid
                                key={index}
                                item
                                xs={12}
                                sm={6}
                                md={spacingValue}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        width: '100%',
                                        height: {
                                            xs: 'auto',

                                            md: '650px',
                                        },
                                    }}
                                >
                                    <img
                                        src={elem?.url}
                                        style={{
                                            width: '100%',
                                            objectFit: 'cover',
                                            height: '100%',
                                        }}
                                        alt='categories'
                                    />
                                    {hasBackgroundText && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    textAlign: 'center',
                                                    color: '#fff',
                                                    fontSize: '2rem',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {elem?.collection_name}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: '1.5rem',
                                    }}
                                >
                                    {hasTitles && (
                                        <Typography
                                            variant='h5'
                                            fontSize={'40px'}
                                            fontWeight={'400'}
                                        >
                                            {elem?.collection_name}
                                        </Typography>
                                    )}
                                    {hasButtons && (
                                        <Button
                                            sx={{
                                                margin: '1rem 0rem',
                                            }}
                                            variant='outlined'
                                            onClick={() => {
                                                navigate(
                                                    `../item_group/${elem.item_group_code}/application/${elem.application_name}/collection/${elem.collection_name}`
                                                );
                                            }}
                                        >
                                            Explore More
                                        </Button>
                                    )}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
            {/* </Container> */}
        </>
    );
}

export default NewArrivalsGrid;
