import { useState } from "react";
import { Box, Typography, Modal, Paper } from "@mui/material";

function GenericTextModal({ title, content, openModal, setOpenModal }: any) {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {xs: '90%', lg:800},
    height: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  };
  return (
    <Box>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="caption"
            sx={{
              color: "#000",
              fontSize: "18px",
            }}
          >
            {title}
          </Typography>
          <br />
          {content.map((data: any, index: number) => (
            <Typography
            key={index}
            id="modal-modal-description"
            sx={{ mt: 4 }}
            variant="caption"
          >
            {data.text} <br />
          </Typography>
          ))},
        </Box>
      </Modal>
    </Box>
  );
}

export default GenericTextModal;
