import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Button } from "@mui/material";
import MissingContent from "../errorMessages/MissingContent";

function Internationals({ data, marginTop, marginBottom, isReverse, description }: any) {
  const navigate = useNavigate();
  return (
    <>
      {data === null || data.length === 0 ? (
        <MissingContent sectionName="Internationals Section" />
      ) : (
        <Grid
          mt={{ xs: "0", lg: marginTop }}
          mb={marginBottom}
          container
          sx={{ width: "100%", height: { xs: "auto", lg: "auto" } }}
        >
          <Box
            sx={{
              display: { xs: "flex" },
              flexDirection: { xs: "column", lg: "row" },
              width: { xs: "100%", lg: "50%" },
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={data[0]?.url}
              alt="trade"
            />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", lg: "50%" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFF",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: { xs: "90%", lg: "80%" },
                flexDirection: "column",
                justifyContent: "center",
                alignItems: { xs: "flex-start", lg: "center" },
                p: { xs: "0 0rem", sm: "0" },
              }}
            >
              <Box>
                <Typography
                  mt={2}
                  variant="h3"
                  fontWeight={"500"}
                  lineHeight={"72px"}
                  fontSize={"56px"}
                >
                  Hertex Internationals
                </Typography>

                <Typography
                  mt={{xs: 0, lg:2}}
                  sx={{
                    color: "#000",
                    fontWeight: "300",
                    lineHeight: "28px",
                    fontSize: "16px",
                  }}
                >
{description}                
</Typography>
                <Typography
                  sx={{
                    color: "#000",
                    fontSize: { xs: "18px", lg: "24px" },
                    fontWeight: "300",
                  }}
                >
             </Typography>
                <Button
                  sx={{
                    mt: "3rem",
                    width: { xs: "100%", lg: "200px" },
                    
                  }}
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    navigate("internationals");
                  }}
                >
                  Explore More
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      )}
    </>
  );
}

export default Internationals;
