import {
    Box,
    Grid,
    Divider,
    Typography,
    Container,
    Stack,
} from '@mui/material';
import logo from '../../../../assets/logos/hertex-logo-light.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { fabricsData, companyData, contactData, wallpaperData } from './footerData';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
    ProductStatResponseModel,
    ProductStatsModel,
} from '../../../../api/apiProduct';
import { useState } from 'react';
import { apiProductProperties } from '../../../../api/apiMultiFilter';

function Footer() {
    const dateNow = new Date();
    const [brandsContent, setBrandsContent] = useState<string[]>([]);

    const navigate = useNavigate();

    useQuery<ProductStatResponseModel, Error>(
        'brands',
        () =>
            apiProductProperties({
                data: {
                    items: {
                        get_count: false,
                        item_group: '',
                        required_item_info: ['brand'],
                    },
                },
            } as ProductStatsModel),
        {
            onSuccess: (data) => {
                const apiArray: string[] | undefined = data.brand;
                const internationals = 'Internationals';
                setBrandsContent([...(apiArray ?? []), internationals]);
            },
            onError: (error) => {
                console.error(error);
            },
            cacheTime: 15 * 60 * 1000,
        }
    );

    return (
        <Grid
            sx={{
                backgroundColor: '#000000',
                width: '100%',
                color: '#fff',
                padding: { xs: '20px 0', md: '80px 64px' },
            }}
        >
            <Container maxWidth='xl'>
                {/* Desktop */}

                <Box
                    onClick={() => navigate('/')}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '93px',
                        cursor: 'pointer',
                    }}
                >
                    <img
                        style={{ width: '221px', height: '43px' }}
                        src={logo}
                        alt='logo'
                    />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', lg: 'row' },
                        justifyContent: 'space-between',

                        paddingBottom: '64px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '20px',
                        }}
                    >
                        <Typography
                            variant='caption'
                            sx={{
                                mb: 2,
                                fontSize: '14px',
                                fontWeight: '600',
                                textTransform: 'uppercase',
                                lineHeight: '18px',
                                cursor: 'pointer',
                                color: '#FFF',
                            }}
                            onClick={() => navigate('../item_group/005')}
                        >
                            Fabric
                        </Typography>
                        {fabricsData.map((fabrics) => (
                            <Typography
                                variant='caption'
                                sx={{
                                    color: '#9A9A9A',
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    textTransform: 'uppercase',
                                    paddingBottom: '5px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate(fabrics.link)}
                            >
                                {fabrics.subtitle}
                            </Typography>
                        ))}
                        <Typography
                            variant='caption'
                            sx={{
                                mb: 2,
                                mt: 2,
                                fontSize: '14px',
                                fontWeight: '600',
                                textTransform: 'uppercase',
                                lineHeight: '18px',
                                cursor: 'pointer',
                                color: '#FFF',
                            }}
                            onClick={() => navigate('../item_group/001/application/hertex wallpaper')}
                        >
                            Wallpaper
                        </Typography>
                        {wallpaperData.map((wallpaper) => (
                            <Typography
                                variant='caption'
                                sx={{
                                    color: '#9A9A9A',
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    textTransform: 'uppercase',
                                    paddingBottom: '5px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate(wallpaper.link)}
                            >
                                {wallpaper.subtitle}
                            </Typography>
                        ))}

                        {/* !Outdoor removed - client may revert */}
                        {/* <Typography
              variant="caption"
              sx={{
                mb: 2,
                mt: 2,
                fontSize: "14px",
                fontWeight: "600",
                textTransform: "uppercase",
                lineHeight: "18px",
                cursor: "pointer",
                color: "#FFF",
              }}
              onClick={() => navigate("../item_group/9001/application_type/outdoor")}
            >
              Outdoor
            </Typography> */}
                    </Box>

                    {/* <Divider
            sx={{
              backgroundColor: "#6E6E6E",
              width: "1px",
            }}
          ></Divider>
          
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                mb: 2,
                fontSize: "14px",
                fontWeight: "600",
                textTransform: "uppercase",
                lineHeight: "18px",
                cursor: "pointer",
              }}
              onClick={() => navigate("../item_group/004")}
            >
              Rugs
            </Typography>
          </Box> */}

                    <Divider
                        sx={{
                            backgroundColor: '#6E6E6E',
                            width: '1px',
                        }}
                    ></Divider>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            // paddingBottom: "20px",
                        }}
                    >
                        <Box
                            sx={{
                                mb: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography
                                variant='caption'
                                sx={{
                                    // mb: 2,
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    textTransform: 'uppercase',
                                    lineHeight: '18px',
                                    pb: '10px',
                                    color: '#FFF',
                                }}
                            >
                                Rugs
                            </Typography>
                            <a
                                href='https://hertexhaus.co.za/pages/rugs'
                                target='_blank'
                                style={{
                                    textDecoration: 'none',
                                }}
                                rel='noreferrer'
                            >
                                <Typography
                                    variant='caption'
                                    sx={{
                                        color: '#9A9A9A',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        textTransform: 'uppercase',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    shop rugs online @ hertexhaus.co.za
                                </Typography>
                            </a>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                mb: 2,
                            }}
                        >
                            <Typography
                                variant='caption'
                                sx={{
                                    color: '#FFF',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    textTransform: 'uppercase',
                                    lineHeight: '18px',
                                    pb: '10px',
                                }}
                            >
                                Homeware
                            </Typography>
                            <a
                                href='https://hertexhaus.co.za/'
                                target='_blank'
                                style={{
                                    textDecoration: 'none',
                                }}
                                rel='noreferrer'
                            >
                                <Typography
                                    variant='caption'
                                    sx={{
                                        color: '#9A9A9A',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        textTransform: 'uppercase',
                                        pb: '10px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    shop homeware online @ hertexhaus.co.za
                                </Typography>
                            </a>
                        </Box>
                        {/* <Box
              sx={{
                display: "flex",
                gap: { xs: "0", lg: "35px" },
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "50%" }}>
                {homewareData.map((homewares) => {
                  if (homewares.section === "1") {
                    return (
                      <Typography
                        sx={{
                          color: "#9A9A9A",
                          fontSize: "12px",
                          fontWeight: "600",
                          textTransform: "uppercase",
                          paddingBottom: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(homewares.link)}
                      >
                        {homewares.subtitle}
                      </Typography>
                    );
                  }
                  return null;
                })}
              </Box>
              <Box sx={{ width: "50%" }}>
                {homewareData.map((homewares) => {
                  if (homewares.section === "2") {
                    return (
                      <Typography
                        sx={{
                          color: "#9A9A9A",
                          fontSize: "12px",
                          fontWeight: "600",
                          textTransform: "uppercase",
                          paddingBottom: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(homewares.link)}
                      >
                        {homewares.subtitle}
                      </Typography>
                    );
                  }
                  return null;
                })}
              </Box>
            </Box> */}
                    </Box>

                    <Divider
                        sx={{
                            backgroundColor: '#6E6E6E',
                            width: '1px',
                        }}
                    ></Divider>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '20px',
                        }}
                    >
                        <Typography
                            variant='caption'
                            sx={{
                                color: '#FFF',
                                mb: 2,
                                fontSize: '14px',
                                fontWeight: '600',
                                textTransform: 'uppercase',
                                lineHeight: '18px',
                            }}
                        >
                            Brands
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: { xs: '0', lg: '35px' },
                                justifyContent: 'space-between',
                            }}
                        >
                            <Stack
                                sx={{ flexWrap: 'wrap', maxWidth: 100 }}
                                direction='row'
                            >
                                {brandsContent?.sort().map((brand, index) => (
                                    <Typography
                                        variant='caption'
                                        key={index}
                                        sx={{
                                            color: '#9A9A9A',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            textTransform: 'uppercase',
                                            cursor: 'pointer',
                                            whiteSpace: 'nowrap',
                                            width: '100%',
                                            marginBottom: 1,
                                            ':nth-of-type(n+7)': {
                                                width: '100%',
                                                marginTop: 0,
                                                clear: 'left',
                                            },
                                        }}
                                        onClick={() => {
                                            if (brand === 'HAUS') {
                                                window.open(
                                                    'https://hertexhaus.co.za/',
                                                    '_blank'
                                                );
                                            } else if (
                                                brand === 'Internationals'
                                            ) {
                                                navigate('/internationals');
                                            } else {
                                                navigate(
                                                    `/item_group/9000/brand/${brand}`
                                                );
                                            }
                                        }}
                                    >
                                        {brand}
                                    </Typography>
                                ))}
                            </Stack>
                        </Box>
                    </Box>
                    <Divider
                        sx={{
                            backgroundColor: '#6E6E6E',
                            width: '1px',
                            // mt: 3,
                        }}
                    ></Divider>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            variant='caption'
                            sx={{
                                color: '#FFF',
                                mb: 2,
                                fontSize: '14px',
                                fontWeight: '600',
                                textTransform: 'uppercase',
                                lineHeight: '18px',
                            }}
                        >
                            Company
                        </Typography>
                        {companyData.map((companies) => (
                            <Typography
                                variant='caption'
                                sx={{
                                    color: '#9A9A9A',
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    textTransform: 'uppercase',
                                    paddingBottom: '5px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate(companies.link)}
                            >
                                {companies.subtitle}
                            </Typography>
                        ))}
                    </Box>
                    <Divider
                        sx={{
                            backgroundColor: '#6E6E6E',
                            width: '1px',
                            // mt: 3,
                        }}
                    ></Divider>
                    <Box
                        sx={{
                            pt: { xs: '40px', lg: '0' },
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            variant='caption'
                            sx={{
                                color: '#FFF',
                                mb: 2,
                                fontSize: '14px',
                                fontWeight: '600',
                                textTransform: 'uppercase',
                                lineHeight: '18px',
                            }}
                        >
                            Contact
                        </Typography>
                        {contactData.map((contact) => (
                            <Box
                                sx={{
                                    pb: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography
                                    variant='caption'
                                    sx={{
                                        color: '#9A9A9A',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        textTransform: 'uppercase',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    {contact.subtitle}
                                </Typography>

                                <Typography
                                    variant='caption'
                                    sx={{
                                        color: '#9A9A9A',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        textTransform: 'uppercase',
                                        paddingBottom: '5px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        window.open(contact.link, '_blank')
                                    }
                                >
                                    {contact.text}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>

                {/* Mobile */}
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'none' },
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'column', sm: 'unset' },
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <img style={{ width: '35%' }} src={logo} alt='logo' />

                        <Box sx={{ width: '300px', mt: 2 }}>
                            <Typography>
                                With Both Contemporary and Timeless Designs,
                                Hertex Are Leaders In Fabric Décor & Design.
                            </Typography>
                        </Box>
                    </Box>
                    <Grid mt={3} container justifyContent='space-between'>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography sx={{ mb: 2 }}>Fabrics</Typography>
                            <Typography>Link 1</Typography>
                            <Typography>Link 2</Typography>
                            <Typography>Link 3</Typography>
                            <Typography>Link 4</Typography>
                            <Typography>Link 5</Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography sx={{ mb: 2 }}>Collections</Typography>
                            <Typography>Link 1</Typography>
                            <Typography>Link 2</Typography>
                            <Typography>Link 3</Typography>
                            <Typography>Link 4</Typography>
                            <Typography>Link 5</Typography>
                        </Box>
                    </Grid>
                    <Grid container justifyContent='space-between'>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography>Brands</Typography>
                            <Typography>Link 1</Typography>
                            <Typography>Link 2</Typography>
                            <Typography>Link 3</Typography>
                            <Typography>Link 4</Typography>
                            <Typography>Link 5</Typography>
                        </Box>
                        <Box
                            sx={{
                                mr: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography sx={{ mb: 1 }}>Company</Typography>
                            <Typography>Link 1</Typography>
                            <Typography>Link 2</Typography>
                            <Typography>Link 3</Typography>
                            <Typography>Link 4</Typography>
                            <Typography>Link 5</Typography>
                        </Box>
                    </Grid>
                </Box>
                <Divider
                    sx={{
                        backgroundColor: '#6E6E6E',
                        mt: 3,
                    }}
                ></Divider>
                <Grid
                    container
                    sx={{
                        mt: { xs: 3, md: 3 },
                    }}
                    justifyContent='space-between'
                >
                    <Box sx={{ display: 'flex' }}>
                        <Typography
                            variant='caption'
                            mr={3}
                            fontWeight={'600'}
                            color={'#FFF'}
                        >
                            {dateNow.getFullYear()} Hertex. All rights reserved.
                        </Typography>
                    </Box>
                    <Box mr={2} sx={{ display: 'flex', mt: { xs: 0, md: 0 } }}>
                        <Box>
                            <a
                                href='https://www.facebook.com/hertexfabrics/'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <FacebookIcon
                                    sx={{ mr: 1 }}
                                    style={{ color: '#FFF' }}
                                />
                            </a>
                        </Box>
                        <a
                            href='https://www.linkedin.com/company/hertexfabrics/mycompany/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <LinkedInIcon
                                sx={{ mr: 1 }}
                                style={{ color: '#FFF' }}
                            />
                        </a>
                        <Box>
                            <a
                                href='https://www.instagram.com/hertexfabrics/'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <InstagramIcon style={{ color: '#FFF' }} />
                            </a>
                        </Box>
                    </Box>
                </Grid>
            </Container>
        </Grid>
    );
}

export default Footer;
