// import PageLayout from '../../components/shared/layout/PageLayout';
import CarouselBanner from '../../components/shared/banner/CarouselBanner';
import TextHeader from '../../components/textHeader/TextHeader'

import {
    carouselImages,
    carouselVideos,
    newCataloguesBookData,
    categoryImages,
    catalogueData,
} from '../../api/tempData';
import { useEffect } from 'react';
import CatalogueGrid from '../../components/shared/grid/CatalogueGrid';




function BrandsPage() {
    const data = {
        textHeaderData: {
            heading: 'Internationals',
            content:
                'Lorem ipsum dolor sit amet, consectetur adipisicing elit, Maxime, accusamus eum cum voluptatum alias dolorem officiis eligendi anothe accusa eum cum voluptatum alias vitae dolorem officiis eligendi consectetur adipisicing accusamus eum cum laboris nisi ut',
            subContent:
                'Ut enim ipsum dolor sit amet consectetur adipisicing elit. accusamus eum cum.',
        },
        carouselImages: carouselImages,
        carouselVideos: carouselVideos,
        categoryImages: categoryImages,
        featuredCollectionList: newCataloguesBookData,
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        // <PageLayout>
        <>
            <CarouselBanner
                images={data.carouselImages}
                hasVideo={false}
                video={carouselVideos}
            />
            <TextHeader
                heading={data.textHeaderData.heading}
                content={data.textHeaderData.content}
                subContent={data.textHeaderData.subContent}
                isCentered={false}
                width='70%'
                marginTop={10}
                marginBottom={5}
            />

<CatalogueGrid
                header='View Our Latest Catalogues'
                catalogueData={catalogueData}
            />

            {/* <NewArrivalsGrid
                header='View new catalogues'
                data={data.featuredCollectionList}
                hasTitles={false}
                hasButtons={true}
                spacingValue={4}
            /> */}
            </>
        // </PageLayout>
    );
}

export default BrandsPage;
